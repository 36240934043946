import { combineReducers } from "redux";
import {
	TOGGLE_NOTIFICATION,
} from "../actions/account.action";
const initialState = false;

function notificationReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_NOTIFICATION:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ notificationReducer });
