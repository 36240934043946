import { GET_SLIDESHOW, GET_SLIDE_LIVE } from "../actions/slideshow.action";
import {combineReducers} from "redux";

const initialState = [];

function slideShowReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SLIDESHOW:
			return action.payload;
		default:
			return state;
	}
}

function slideShowReducerLive(state = initialState, action) {
	switch (action.type) {
		case GET_SLIDE_LIVE:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({
	slideShowReducerLive,
	slideShowReducer
});
