import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../components/Utils";
import Moment from "react-moment";
import { useMediaQuery } from "react-responsive";
import {clearVideo, getVideo} from "../actions/videos.action";
import { toggleModal } from "../actions/modal.action";
import PlaylistVideos from "../components/PlaylistVideos";
import VideoJS from "./VideoPlayer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MyContext } from "../contexts/MyContext";
import { Redirect } from "react-router";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import Stripe from "../components/Stripe";

const shareFb = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href);
const shareTw = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href);

const Player = ({ match }) => {

	const {
		params: { videoID },
	} = match;

	const dispatch = useDispatch();
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const videoReducer = useSelector(
		(state) => state.videosReducer.getVideoReducer
	);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);
	const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
	const [video, setVideo] = useState();
	const [state, setState] = useState(null);
	const [playerClass, setPlayerClass] = useState(null);
	const [ads, setAds] = useState(null);
	const [inFavorite, setInFavorite] = useState(0);
	const [isHasVote, setIsHasVote] = useState(0);
	const [notHaveAccess, setNotHaveAccess] = useState(false);
	const [needPay, setNeedPay] = useState(false);
	const [needPayMinHierarchy, setNeedPayMinHierarchy] = useState(null);
	const { rootState, setVideoRating, addToVideoFavorite } = useContext(MyContext);
	const { isAuth, redirect } = rootState;

	useEffect(() => {
		async function auth() {
			if (isAuth) {
				localStorage.removeItem('loginRedirectUrl');
			} else {
				localStorage.setItem('loginRedirectUrl', window.location);
			}
			if (redirect !== null) setState(!redirect);
		}
		auth();
	}, [isAuth, redirect]);

	useEffect(() => {
		if (videoReducer.result && videoReducer.code === 403) {
			setNotHaveAccess(true);
		} else if (!isEmpty(videoReducer.video)) {
			setNotHaveAccess(false);
			setVideo(videoReducer.video);
			setAds(videoReducer.video?.ads?.data);
			setIsHasVote(parseInt(videoReducer.video.is_has_vote));
			setInFavorite(parseInt(videoReducer.video.in_favorite));
		}
	}, [videoReducer]);

	useEffect(() => {
		if (isAuth) {
			dispatch(clearVideo());
			dispatch(getVideo(videoID));
			setNeedPay(false);
		}
	}, [dispatch, isAuth, videoID]);

	useEffect(() => {
		return () => {
			dispatch(clearVideo());
		};
	}, [dispatch]);

	//check if player ready and initial datas
	useEffect(() => {
		if (!isEmpty(playerClass) && playerClass.isDisposed_ !== true) {
			playerClass.preroll()
				.preRollUpdate(
					!isEmpty(ads?.videos) && (!isEmpty(ads?.activated.AD_PRE_RL) && ads?.activated.AD_PRE_RL === true) ?
						ads?.videos :
						{}
				);

			playerClass.sponsors()
				.sponsorUpdate(
					!isEmpty(ads?.sp_banner) && (!isEmpty(ads?.activated.AD_SP_BNR) && ads?.activated.AD_SP_BNR === true) ?
						{...ads.sp_banner, displayTime: 5} :
						{
							background: null,
							headings: null,
							logos: null,
							sponsors: null,
							displayTime: 5
						});

			playerClass.pills()
				.updateState(
					!isEmpty(ads?.pills) && (!isEmpty(ads?.activated.AD_TBL_LG) && ads?.activated.AD_TBL_LG === true) ? ads?.pills :
						{
							hide_animation: null,
							images: [],
							secondary_display_time: null,
							secondary_times: null,
							show_animation: null

						});

			playerClass.toasts()
				.toastsUpdate(!isEmpty(ads?.toasts) && (!isEmpty(ads?.activated.AD_UP_TST) && ads?.activated.AD_UP_TST === true) ?
					ads?.toasts :
					{
						primary: null,
						secondary: null,
						secondary_display_time: null,
						secondary_hide_animation: null,
						secondary_show_animation: null,
						secondary_times: null,
					});

			playerClass.watermark()
				.updateState(
					!isEmpty(ads?.watermark) && (!isEmpty(ads?.activated.AD_WTRMRK) && ads?.activated.AD_WTRMRK === true) ?
						{
							image: ads?.watermark,
							position: ads?.watermark_position,
							fadeTime: null
						} :
						{
							image: null,
							position: null,
							fadeTime: null
						});

			playerClass.on('needToPay', (event, status) => needToPay(event, status))

			handleInitPlayerReady();

		}
		// eslint-disable-next-line
	}, [ads, playerClass]);

	const needToPay = (event, status) => {
		setNeedPayMinHierarchy(video.subscription.min_hierarchy)
		setNeedPay(status);
	}

	const handlePlayerReady = (player) => {
		setPlayerClass(player);
	};

	//start after init player and implemented ads
	const handleInitPlayerReady =() => {
		if (playerClass && playerClass.isDisposed_ !== true) {
			if (ads?.videos && Object.keys(ads?.videos).length > 0 && playerClass.preroll().ended === false) {
				playerClass.watermark().hideWaterMark();
				playerClass.toasts().toastsStop();
				playerClass.pills().pillsStop();
				playerClass.one("endallpreroll",  () => {
					if (!isEmpty(ads?.sp_banner)) {
						playerClass.sponsors().startSponsors();
						playerClass.one("sponsorstop", () => {
							initPlayer();
						})
					} else {
						initPlayer();
					}
				})
			} else {
				if (!isEmpty(ads?.sp_banner)) {
					playerClass.watermark().hideWaterMark();
					playerClass.toasts().toastsStop();
					playerClass.sponsors().startSponsors();
					playerClass.one("sponsorstop", () => {
						initPlayer();
					})
				} else {
					initPlayer();
				}
			}
		}
	};

	//start default player
	const initPlayer = () => {
		playerClass.updateSrc([
			{
				src: video.url.high ? video.url.high : null,
				type: 'video/mp4',
				label: 'HIGH',
				res: 1080
			},
			{
				src: video.url.med ? video.url.med : null,
				type: 'video/mp4',
				label: 'MED',
				res: 720
			},
			{
				src: video.url.low ? video.url.low : null,
				type: 'video/mp4',
				label: 'LOW',
				res: 360
			}
		]);

		playerClass.subscription().updateState({
			can_view: video.subscription?.can_view,
			entity: "video"
		});
	};

	const handleCopy = () => {

		let	text = translations && !isEmpty(translations["copy_clipboard"])
		? translations["copy_clipboard"]
		: "Copy in clipboard !"

		dispatch(toggleModal({opened: true, success: true, text: text}))
	};

	const handleRating = (stars) => {
		const setRating = async () => {
			await setVideoRating(video.id, stars)
				.then((res) => {
					if (res.result === "ok") {
						setIsHasVote(stars);

						let	text = translations && !isEmpty(translations["thank_for_vote"])
								? translations["thank_for_vote"]
								: "Thank you for your vote"

						dispatch(toggleModal({opened: true, success: true, text: text}))
					}
				})
		};
		if (isHasVote !== stars) {
			setRating();
		}
	};

	const handleFavorite = (status) => {

		const setFavorite = async () => {
			await addToVideoFavorite(video.id)
				.then((res) => {
					if (res.result === "ok") {
						setInFavorite(status);
						let text;
						if (status) {
							text = translations && !isEmpty(translations["video_added_to_list"])
								? translations["video_added_to_list"]
								: "Your video has been added to your list!"
						} else {
							text = translations && !isEmpty(translations["video_removed_from_list"])
								? translations["video_removed_from_list"]
								: "Your video has been removed from your list!"
						}

						dispatch(toggleModal(
							{
								opened: true,
								success: true,
								text: text
							})
						)
					}
				})
		};

		setFavorite();
	};

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/`);
		}
	};

	return (
		<div>

			{!isEmpty(video) && (
				<Helmet>
					<title>{`${process.env.REACT_APP_CLIENT_NAME} - ${video.name}`}</title>
					<meta name="title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${video.name}`} />
					<meta property="og:title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${video.name}`} />
					<meta property="og:description" content={`${video.description}`} />
					<meta property="og:image" content={`${video.poster}`} />
				</Helmet>
			)}

			{state === false && <Redirect to="/account/login" />}
				<div id="video_player_bg"/>
				<div className="player-page container">
					<div className="d-flex">
						<span className="back" onClick={() => clickBack()}/>
						{!isEmpty(video) && !notHaveAccess && (
							<div className="video-iframe" id="video">
								<div className="video position-relative">
									{needPay ? (
										<Stripe
											minHierarchy={needPayMinHierarchy}
											poster={video ? video.poster_desktop : ""}
											entityType={"video"}
											entityId={video?.id}
											priceForView={video?.subscription?.price_per_view}
										/>
									) : (
										<VideoJS
											options={
												{
													autoplay: false,
													controls: true,
													responsive: true,
													playsinline: true,
													fill: true,
													userActions: {
														doubleClick: false
													},
													videoId: videoID,
													liveId: null,
													poster: video ? video.poster_desktop : "",
													plugins: {
														videoJsResolutionSwitcher: {
															default: 720,
															dynamicLabel: true
														},
													}
												}
											}
											onReady={handlePlayerReady}
										/>
									)}

								</div>
								<div className="video-data">
									<div className="first-row">

										<h2>{!isEmpty(video) && video.name}</h2>

										<div className="views-block">
											<div className="stars">
												<input readOnly type="radio" id="star5" name="star5" value="5"
													   checked={parseInt(video.stars) >= 5 ? 'checked' : ''}
													   onClick={() => handleRating(5)}
												/>
												<label htmlFor="star5" title="5 stars">5 stars</label>

												<input readOnly type="radio" id="star4" name="star4" value="4"
													   checked={parseInt(video.stars) >= 4 ? 'checked' : ''}
													   onClick={() => handleRating(4)}
												/>
												<label htmlFor="star4" title="4 stars">4 stars</label>

												<input readOnly type="radio" id="star3" name="star3" value="3"
													   checked={parseInt(video.stars) >= 3 ? 'checked' : ''}
													   onClick={() => handleRating(3)}
												/>
												<label htmlFor="star3" title="3 stars">3 stars</label>

												<input readOnly type="radio" id="star2" name="star2" value="2"
													   checked={parseInt(video.stars) >= 2 ? 'checked' : ''}
													   onClick={() => handleRating(2)}
												/>
												<label htmlFor="star2" title="2 stars">2 stars</label>

												<input readOnly type="radio" id="star1" name="star1" value="1"
													   checked={parseInt(video.stars) >= 1 ? 'checked' : ''}
													   onClick={() => handleRating(1)}
												/>
												<label htmlFor="star1" title="1 star">1 star</label>
											</div>

											<div className="views">
												<span>{video.views} views</span>
												<Moment format="DD/MM/YYYY">{video.added}</Moment>
											</div>

										</div>
									</div>

									<div className="description">
										{video.description}
									</div>

									<div className="buttons">
										<button
											className={`button button-blue ${inFavorite > 0 ? "minus" : "plus"}`}
											onClick={() => handleFavorite(!inFavorite)}
										>{inFavorite > 0 ? "Remove list" : "Ajouter à ma liste"}</button>

										<div className={open ? "share-open": "share-closed"}>
											<button className="button button-blue shared" id="share_btn" onClick={() => setOpen(!open)}>Partager
												<svg id="arrow-menu"  width="4" height="7" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1.05273 13.5577C1.346 13.5577 1.5791 13.4524 1.78213 13.2569L7.54961 7.6248C7.80527 7.36914 7.92559 7.10596 7.93311 6.78262C7.93311 6.45928 7.81279 6.18857 7.54961 5.94043L1.78213 0.300781C1.5791 0.105273 1.33848 0 1.05273 0C0.466211 0 0 0.466211 0 1.04521C0 1.33096 0.120313 1.60166 0.330859 1.81221L5.44414 6.78262L0.330859 11.7455C0.120313 11.9561 0 12.2268 0 12.5125C0 13.0915 0.466211 13.5577 1.05273 13.5577Z" fill="white"/>
												</svg>
											</button>
											<ul className="share-sub">
												<li>
													<a href={shareFb} target="_blank" rel="noopener noreferrer">Facebook</a>
												</li>
												<li>
													<a href={shareTw} target="_blank" rel="noopener noreferrer">Twitter</a>
												</li>
												<li>
													<CopyToClipboard onCopy={handleCopy} text={window.location.href}>
														<button className="copy-link">Copy Link</button>
													</CopyToClipboard>
												</li>
											</ul>
										</div>


									</div>

								</div>
							</div>
						)}
						{notHaveAccess && (
							<div className="m-auto d-flex min-height-30">
								<h3 className="m-auto">You don't have permission to view</h3>
							</div>
						)}
					</div>
					{!isMobile ? (
						<div className="similar-video-container">
							{!isEmpty(video) && !isEmpty(video.similar.videos) &&
							<PlaylistVideos
								name={
									translations && !isEmpty(translations["similar_videos"])
										? translations["similar_videos"]
										: "Similar videos"
								}
								videos={video.similar.videos}
							/>
							}
						</div>
					) : (
						<div className="similar-video-container">

							{!isEmpty(video) && !isEmpty(video.similar.videos) && (
								<h3>{translations && !isEmpty(translations["similar_videos"])
									? translations["similar_videos"]
									: "Similar videos"}</h3>
							)}

							<div className="content">
								{!isEmpty(video) && !isEmpty(video.similar.videos) &&
								video.similar.videos.map((video) => {
									return (
										<div className="video" key={video.id}>
											<Link
												draggable="false"
												rel="noopener noreferrer"
												to={`/video/${video.id}`}
												onClick={() => window.scrollTo(0, 0)}
											>
												<img
													className="image"
													src={video.image_desktop}
													alt={`Apercu de ${video.name}`}
													width="100"
													height="66"
												/>
												<div className="title-container">
													<div className="play">
														<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
															 fill="none" xmlns="http://www.w3.org/2000/svg">
															<path className="round"
																  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
																  fill="#362CAE"/>
															<path className="play_sign"
																  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
																  fill="white"/>
														</svg>
													</div>
													<div className="video-date">
														<div className="line">
															<svg className="bar" width="100" height="4" viewBox="0 0 100 4" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect width="100" height="4" rx="2" fill="url(#paint0_radial_1607_3199)"/>
																<defs>
																	<radialGradient id="paint0_radial_1607_3199" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 2) rotate(90) scale(2 27)">
																		<stop stopColor="#4E45AF"/>
																		<stop offset="1" stopColor="#362CAE"/>
																	</radialGradient>
																</defs>
															</svg>
														</div>
														<div className="date">
															{video.addedon && moment(video.addedon).format("DD/MM/YYYY")}
														</div>
													</div>
													<h4>{video.name}</h4>
													<div className="video-description">
														{!isEmpty(video.descr) && video.descr.length > 180 ? video.descr.slice(0,180) + "..." : video.descr}
													</div>
												</div>
											</Link>
										</div>
									)
								})}
							</div>
						</div>

					)}
				</div>
		</div>
	);
};

export default Player;