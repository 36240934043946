import React, {useContext, useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import {isEmpty} from "./Utils";
import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import moment from "moment";
import {MyContext} from "../contexts/MyContext";
import {toggleModal} from "../actions/modal.action";
import {getPrivatePlaylists} from "../actions/playlist.action";
const PrivatePlaylistVideos = (props) => {
	const {id, name, videos, nextPage} = props;
	const { leavePrivatePlaylists, rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const sliderRef = useRef(null);
	const slider = useRef();
	const dispatch = useDispatch();
	const [width, setWidth] = useState();
	const [videoArray, setVideoArray] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(5);
	const [hasNexPage, setHasNextPage] = useState(nextPage);
	const [countPage, setCountPage] = useState(1);

	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;

	const getWidth = () => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			const newWidth = sliderRef.current.clientWidth;
			setWidth(newWidth);
		}
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 150);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			getWidth();
		}
	}, []);

	useEffect(() => {
		window.addEventListener("resize", getWidth);
		return () => {
			window.removeEventListener("resize", getWidth);
		};
	}, []);

	let settings = {
		infinite: false,
		//lazyLoad: true,
		speed: 500,
		slidesToShow: Math.ceil((isEmpty(width) ? 700 : width) / 300),
		slidesToScroll: itemsPerPage,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		swipeToSlide: false,
		initialSlide: 0,
		centerMode: false,
		afterChange: (index) => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				nextPageHandler();
			}
		}
	};

	useEffect(() => {
		setVideoArray(videos)
	}, [videos]);

	useEffect(() => {
		if (!isEmpty(width)) {
			setItemsPerPage(Math.ceil(width / 300));
		} else {
			setItemsPerPage(5);
		}
	}, [width]);

	const nextPageHandler = () => {
		if (!isEmpty(id) && id !== false && hasNexPage !== null) {
			axios
				.get(`${process.env.REACT_APP_API_URL}getvideosfrom&f=playlist&i=${id}&p=${countPage}&n=10`)
				.then((res) => {
					console.log("get next (" + countPage + ") page for playlistId: " + id);
					setHasNextPage(res.data.nextpage);
					setCountPage(countPage + 1);
					setVideoArray([...videoArray, ...res.data.videos]);
				})
				.catch((error) => {
					console.log(error)
				});
		}

	};

	const leavePlaylist = async () => {
		if (!isEmpty(id) && id !== false && isAuth) {
			let res = await leavePrivatePlaylists(id);
			dispatch(toggleModal({opened: true, success: res.result === "ok", text: res.message}));
			dispatch(getPrivatePlaylists(0, 5));
		}
	}

	return (
		<div className="playlist-videos filter white">
			<div className="titles">
				<section
					className="flex-between m-auto"
				>
					<h3>
						{!isEmpty(name) && name} ({translations && !isEmpty(translations["private"]) ? translations["private"] : "private"})
					</h3>
					<div className="item-menu">
						<h5 onClick={leavePlaylist}>
							{translations && !isEmpty(translations["leave_playlist"])
								? translations["leave_playlist"]
								: "leave_playlist"
							}
							<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 5L5 9.5M5 5L9.5 9.5" stroke="#F41B3B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M7.25 13.5C10.7018 13.5 13.5 10.7018 13.5 7.25C13.5 3.79822 10.7018 1 7.25 1C3.79822 1 1 3.79822 1 7.25C1 10.7018 3.79822 13.5 7.25 13.5Z" stroke="#F41B3B" strokeWidth="1.5" strokeLinecap="#F41B3B" strokeLinejoin="round"/></svg>
						</h5>
					</div>
				</section>
			</div>
			<div ref={sliderRef}>
				<Slider ref={slider} {...settings} className="videos-container hide-scroll customScroll">
					{!isEmpty(videoArray) &&
						videoArray.map((video) => {
							return (
								<div className="video" key={video.id}>
									<NavLink
										rel="noopener noreferrer"
										to={`/video/${video.id}`}
										onClick={() => window.scrollTo(0, 0)}
									>
										<img
											className="image"
											src={video.image_desktop}
											alt={`Apercu de ${video.name}`}
											width="100"
											height="66"
										/>
										<div className="title-container">
											<div className="play">
												<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
													 fill="none" xmlns="http://www.w3.org/2000/svg">
													<path className="round"
														  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
														  fill="#362CAE"/>
													<path className="play_sign"
														  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
														  fill="white"/>
												</svg>
											</div>
											<div className="video-date">
												<div className="line">
													<svg className="bar" width="100" height="4" viewBox="0 0 100 4" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect width="100" height="4" rx="2" fill="url(#paint0_radial_1607_3199)"/>
														<defs>
															<radialGradient id="paint0_radial_1607_3199" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 2) rotate(90) scale(2 27)">
																<stop stopColor="#4E45AF"/>
																<stop offset="1" stopColor="#362CAE"/>
															</radialGradient>
														</defs>
													</svg>
												</div>
												<div className="date">
													{video.addedon && moment(video.addedon).format("DD/MM/YYYY")}
												</div>
											</div>
											<h4>{video.name}</h4>
											<div className="video-description">
												{!isEmpty(video.descr) && video.descr.length > 180 ? video.descr.slice(0,180) + "..." : video.descr}
											</div>
										</div>
									</NavLink>
								</div>
							);
						})}
				</Slider>
			</div>
		</div>
	);
};

export default PrivatePlaylistVideos;
