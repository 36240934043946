import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { getNotifications } from "../../actions/notifications.action";
import Moment from "react-moment";
import axios from "axios";
import { useHistory } from "react-router";
import { MyContext } from "../../contexts/MyContext";
import { toggleNotification } from "../../actions/account.action";
import {isEmpty} from "../Utils";

const Notification = () => {
	const dispatch = useDispatch();
	let history = useHistory();
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const [notifications, setNotifications] = useState([]);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);
	const notificationsReducer = useSelector(
		(state) => state.notificationsReducer
	);
	const notificationToggleReducer = useSelector(
		(state) => state.accountReducer.notificationReducer
	);
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);

	useEffect(() => {
		if (notificationsReducer.result === "ok") {
			setNotifications(notificationsReducer.notifs);
		} else {
			setNotifications([]);
		}
	}, [notificationsReducer]);

	/*id can be 'all'*/
	const updateRead = async (type, id) => {
		const loginToken = localStorage.getItem("loginToken");
		let form = new FormData();
		form.append('key', loginToken);
		let url = '';
		if (type !== "all") {
			url = `${process.env.REACT_APP_API_URL}notifread&i=${id}`;
		} else {
			url = `${process.env.REACT_APP_API_URL}notifreadall`;
		}
		await axios
			.post(url, form)
			.then((res) =>
				res.data.result === "ok"
					? dispatch(getNotifications())
					: console.log("Update Error!")
			)
			.then(() => {
				let link = "/";
				if (type === "1") {
					link = `/video/${id}`;
				}
				history.replace(link);
			});
	};

	useEffect(() => {
		let menu = document.querySelector(".notifications.sub-menu");
		let bckg = document.querySelector(".hide-background");
		let iconNoty = document.querySelector(".account-section .notifications");

		if (notificationToggleReducer) {
			menu.classList.add("hover");
			bckg.classList.add("hover");
			iconNoty.classList.add("hover");
		} else {
			menu.classList.remove("hover");
			bckg.classList.remove("hover");
			if (iconNoty) {
				iconNoty.classList.remove("hover");
			}
		}
	}, [notificationToggleReducer, isAuth]);

	const handleClickOutside = (event) => {
		if (event.target.closest('.notifications') === null) {
			dispatch(toggleNotification(false));
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="notifications sub-menu" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
			<div className="title">
				<span
					onClick={() => {
						dispatch(toggleNotification(!notificationToggleReducer));
					}}
				>
					{translations && !isEmpty(translations["notifications"])
						? translations["notifications"]
						: "Notifications"
					}
				</span>
				<span onClick={() => updateRead("all")}>
					{translations && !isEmpty(translations["markAsRead"])
						? translations["markAsRead"]
						: "Mark as read"
					}
				</span>
			</div>
			<div className="item-rows">
				{notifications &&
					notifications.map((notification, index) => {
						return (
							<div
								className={"content" + (notification.read === "0" ? " new" : "")}
								key={notification.id}
							>
								<div className="item" onClick={() => updateRead(notification.type, notification.nid)}>
									<div className="image">
										<img
											src={notification.thumb}
											alt={notification.msg}
											width="50"
										/>
										{/*if has `started` it is event*/}
										{notification.started === "yes" && (
											<span className="live">
												{translations && !isEmpty(translations["live"])
													? translations["live"]
													: "live"
												}
											</span>
										)}
										{notification.started === "no" && (
											<span className="off">
												{translations && !isEmpty(translations["soon"])
													? translations["soon"]
													: "soon"
												}
											</span>
										)}
									</div>
									<div className="message">
										<div className="message-text">
											{notification.msg}
										</div>
										<span className="date">
											<Moment format="DD/MM/YYYY">{notification.date}</Moment>
										</span>
									</div>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default Notification;
