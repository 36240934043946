import {combineReducers} from "redux";
import {GET_DISCIPLINES} from "../actions/disciplines.action";

const initialState = [];

function getDisciplinesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_DISCIPLINES:
			return action.payload;
		default:
			return state;
	}
}
export default combineReducers({
	getDisciplinesReducer
});