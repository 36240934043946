import {
	GET_EVENT,
	GET_EVENTS,
	CLEAR_EVENT
} from "../actions/events.action";
import { combineReducers } from "redux";

const initialState = [];

function eventsAllReducer(state = initialState, action) {
	switch (action.type) {
		case GET_EVENTS:
			return action.payload;
		default:
			return state;
	}
}

function getEventReducer(state = initialState, action) {
	switch (action.type) {
		case GET_EVENT:
			return action.payload;
		case CLEAR_EVENT:
			return state
		default:
			return state;
	}
}

export default combineReducers({
	eventsAllReducer,
	getEventReducer
});
