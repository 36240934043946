import React, {useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import NavBarMobileHome from "./NavBarMobileHome";
import NavBarMobileProfile from "./NavBarMobileProfile";
import NavBarMobileParam from "./NavBarMobileParam";
import NavBarMobileEmail from "./NavBarMobileEmail";
import {MyContext} from "../../contexts/MyContext";
import NavBarMobilePassword from "./NavBarMobilePassword";
import NavBarMobileLang from "./NavBarMobileLang";
import NavBarMobileDelete from "./NavBarMobileDelete";
import NavBarMobileSubscription from "./NavBarMobileSubscription";
import {isEmpty} from "../Utils";
import {useDispatch, useSelector} from "react-redux";
import NavBarMobileSubscriptionSummary from "./NavBarMobileSubscriptionSummary";
import NavBarMobileSubscriptionPlans from "./NavBarMobileSubscriptionPlans";
import NavBarMobileSubscriptionInvoice from "./NavBarMobileSubscriptionInvoice";
import NavBarMobileSubscriptionPayment from "./NavBarMobileSubscriptionPayment";
import {
	getActiveCustomerProduct,
	getCustomer,
	getInvoices,
	getListPaymentsMethods, getPaymentsIntents,
	getProducts
} from "../../actions/stripe.action";

const NavBarMobile = () => {
	const stripeReducer = useSelector((state) => state.stripeReducer);
	const customer = stripeReducer.getCustomerReducer;
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const dispatch = useDispatch();
	const [menuType, setTypeMenu] = useState(null);

	useEffect(() => {
		if (!isEmpty(settings) && settings.subscription_features === "yes") {
			dispatch(getCustomer());
		}
	}, [dispatch, settings]);

	useEffect(() => {
		if (customer.id && !isEmpty(settings) && settings.subscription_features === "yes") {
			dispatch(getListPaymentsMethods(customer.id));
			dispatch(getInvoices(customer.id));
			dispatch(getPaymentsIntents(customer.id));
			dispatch(getProducts(1));
			let timeout = setTimeout(() => {
				dispatch(getActiveCustomerProduct())
			}, 2000);
			return () => clearTimeout(timeout);
		}
	}, [customer, dispatch, settings])

	const renderSwitch = (param) => {
		switch(param) {
			case "account":
				return <NavBarMobileProfile setTypeMenu={setTypeMenu}/>;
			case "preference":
				return <NavBarMobileParam setTypeMenu={setTypeMenu}/>;
			case "email":
				return <NavBarMobileEmail setTypeMenu={setTypeMenu}/>;
			case "password":
				return <NavBarMobilePassword setTypeMenu={setTypeMenu}/>;
			case "language":
				return <NavBarMobileLang setTypeMenu={setTypeMenu}/>;
			case "subscription":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					return <NavBarMobileSubscription setTypeMenu={setTypeMenu}/>;
				}
				break;
			case "summary":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					return <NavBarMobileSubscriptionSummary setTypeMenu={setTypeMenu}/>;
				}
				break;
			case "plans":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					return <NavBarMobileSubscriptionPlans setTypeMenu={setTypeMenu}/>;
				}
				break;
			case "invoice":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					return <NavBarMobileSubscriptionInvoice setTypeMenu={setTypeMenu}/>;
				}
				break;
			case "payment":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					return <NavBarMobileSubscriptionPayment setTypeMenu={setTypeMenu}/>;
				}
				break
			case "delete":
				return <NavBarMobileDelete setTypeMenu={setTypeMenu}/>;
			default:
				return <NavBarMobileHome setTypeMenu={setTypeMenu}/>;
		}
	};


	return (
		<nav style={{background: !isEmpty(settings) ? settings.background_color : ""}}>
			<div className="logo">
				<NavLink exact to="/">
					<img
						src={`${process.env.REACT_APP_CACHE_SERVER}/images/logo.png`}
						alt="logo"
					/>
				</NavLink>
			</div>
			<div
				className="mobile-close"
				onClick={() =>
					document.querySelector(".main-header").classList.remove("menu-open")
				}
			>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 18.8481L8.86739 9.98068L0 1.13645L1.11326 0.0231919L9.98065 8.86742L18.8558 0L19.9691 1.07462L11.0939 9.98068L20 18.9177L18.8867 20.0309L9.98065 11.1249L1.11326 19.9614L0 18.8481Z" fill="white"/>
				</svg>
			</div>

			{menuType !== null && isAuth ?
				(
					renderSwitch(menuType)
				) :
				(
					<NavBarMobileHome
						setTypeMenu={setTypeMenu}
					/>
				)
			}
		</nav>
	);
};

export default NavBarMobile;
