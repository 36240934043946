import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTranslations } from "../../actions/translations.action";
import { isEmpty } from "../Utils";

const LanguagesSelection = () => {
	const dispatch = useDispatch();
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const selectedLanguage = translationsReducer.language;
	const [language, setLanguage] = useState(
		!isEmpty(localStorage.getItem("ottLang")) ? localStorage.getItem("ottLang").toUpperCase() : "EN"
	);

	useEffect(() => {
		dispatch(getTranslations(language));
		// eslint-disable-next-line
	}, [language]);
	return (
		<div
			className="languages"
		>
			<img
				src={ selectedLanguage === "EN" ?
					`/img/icons/flag_of_en.svg` :
					'/img/icons/flag_of_fr.svg'
				}
				alt={`flag ${selectedLanguage}`}
				width="20"
				height="20"
			/>

			<ul className="language-sub">
				<li
					onClick={(e) => setLanguage("EN")}
				>
					<img
						src="/img/icons/flag_of_en.svg"
						alt="flag english"
						width="20"
						height="20"
					/>
					<span>English</span>
				</li>
				<li
					onClick={(e) => setLanguage("FR")}
				>
					<img
						src="/img/icons/flag_of_fr.svg"
						alt="flag france"
						width="20"
						height="20"
					/>
					<span>Français</span>
				</li>
			</ul>
		</div>
	);
};

export default LanguagesSelection;
