import React from "react";
import { useSelector } from "react-redux";
import {isEmpty} from "../Utils";


const NavBarMobileSubscription = (props) => {
    const setTypeMenu = props.setTypeMenu;
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translate
    );
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);

    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu("preference")}/>
                        {translations && !isEmpty(translations["subscription"])
                            ? translations["subscription"]
                            : "Subscription"
                        }
                    </h1>
                </div>
            </div>
            <div className="content">
                <h4>
                {translations && !isEmpty(translations["manage_my_subscription"])
                    ? translations["manage_my_subscription"]
                    : "Manage my subscription"
                }
                </h4>
                <ul className="menu-block mt-0 w-100">
                    <li className="flex-between" onClick={() => setTypeMenu("summary")}>
                        <span>
                            {translations && !isEmpty(translations["summary"])
                                ? translations["summary"]
                                : "Summary"
                            }
                        </span>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.05273 13.5577C1.346 13.5577 1.5791 13.4524 1.78213 13.2569L7.54961 7.6248C7.80527 7.36914 7.92559 7.10596 7.93311 6.78262C7.93311 6.45928 7.81279 6.18857 7.54961 5.94043L1.78213 0.300781C1.5791 0.105273 1.33848 0 1.05273 0C0.466211 0 0 0.466211 0 1.04521C0 1.33096 0.120313 1.60166 0.330859 1.81221L5.44414 6.78262L0.330859 11.7455C0.120313 11.9561 0 12.2268 0 12.5125C0 13.0915 0.466211 13.5577 1.05273 13.5577Z" fill="white"/>
                        </svg>
                    </li>
                    <li className="flex-between" onClick={() => setTypeMenu("plans")}>
                        <span>
                            {translations && !isEmpty(translations["plans"])
                                ? translations["plans"]
                                : "Plans"
                            }
                        </span>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.05273 13.5577C1.346 13.5577 1.5791 13.4524 1.78213 13.2569L7.54961 7.6248C7.80527 7.36914 7.92559 7.10596 7.93311 6.78262C7.93311 6.45928 7.81279 6.18857 7.54961 5.94043L1.78213 0.300781C1.5791 0.105273 1.33848 0 1.05273 0C0.466211 0 0 0.466211 0 1.04521C0 1.33096 0.120313 1.60166 0.330859 1.81221L5.44414 6.78262L0.330859 11.7455C0.120313 11.9561 0 12.2268 0 12.5125C0 13.0915 0.466211 13.5577 1.05273 13.5577Z" fill="white"/>
                        </svg>
                    </li>
                    <li className="flex-between" onClick={() => setTypeMenu("invoice")}>
                        <span>
                            {translations && !isEmpty(translations["invoice"])
                                ? translations["invoice"]
                                : "Invoice"
                            }
                        </span>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.05273 13.5577C1.346 13.5577 1.5791 13.4524 1.78213 13.2569L7.54961 7.6248C7.80527 7.36914 7.92559 7.10596 7.93311 6.78262C7.93311 6.45928 7.81279 6.18857 7.54961 5.94043L1.78213 0.300781C1.5791 0.105273 1.33848 0 1.05273 0C0.466211 0 0 0.466211 0 1.04521C0 1.33096 0.120313 1.60166 0.330859 1.81221L5.44414 6.78262L0.330859 11.7455C0.120313 11.9561 0 12.2268 0 12.5125C0 13.0915 0.466211 13.5577 1.05273 13.5577Z" fill="white"/>
                        </svg>
                    </li>
                    <li className="flex-between" onClick={() => setTypeMenu("payment")}>
                        <span>
                            {translations && !isEmpty(translations["payment_info"])
                                ? translations["payment_info"]
                                : "Payment Information"
                            }
                        </span>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.05273 13.5577C1.346 13.5577 1.5791 13.4524 1.78213 13.2569L7.54961 7.6248C7.80527 7.36914 7.92559 7.10596 7.93311 6.78262C7.93311 6.45928 7.81279 6.18857 7.54961 5.94043L1.78213 0.300781C1.5791 0.105273 1.33848 0 1.05273 0C0.466211 0 0 0.466211 0 1.04521C0 1.33096 0.120313 1.60166 0.330859 1.81221L5.44414 6.78262L0.330859 11.7455C0.120313 11.9561 0 12.2268 0 12.5125C0 13.0915 0.466211 13.5577 1.05273 13.5577Z" fill="white"/>
                        </svg>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default NavBarMobileSubscription;
