import React, { Fragment, useEffect, useState } from "react";
import {isEmpty, removeTags} from "../Utils";
import { useDispatch, useSelector } from "react-redux";
import {getFilter} from "../../actions/filter.action";

const Filter = (props) => {
	const onSelectFilter = props.onSelectFilter;
	const disciplineId = props.disciplineId;
	const dispatch = useDispatch();

	const filterReducer = useSelector((state) => state.filterReducer.getFilterReducer);

	const [devices, setDevices] = useState([]);
	const [devicesMenu, setDevicesMenu] = useState(false);
	const [devicesSelect, setDevicesSelect] = useState("");

	const [regions, setRegions] = useState([]);
	const [regionMenu, setRegionMenu] = useState(false);
	const [regionSelect, setRegionSelect] = useState("");

	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const translations = translationsReducer.translate;

	useEffect(() => {
		if (!isEmpty(filterReducer.filters)) {
			setDevices(filterReducer.filters.device);
			setRegions(filterReducer.filters.regions);
		}
	}, [filterReducer]);

	useEffect(() => {
		setDevicesMenu(false);
		setRegionMenu(false);
		// eslint-disable-next-line
	}, [devicesSelect, regionSelect]);


	useEffect(() => {
		if (!isEmpty(disciplineId)) {
			dispatch(getFilter("discipline", 0, disciplineId));
		}
		// eslint-disable-next-line
	}, [disciplineId]);

	const getNameDevice = (id) => {
		let name = "";
		devices.forEach((device) => {
			if (device.id === id) {
				if(!isEmpty(translations["discipline_device_" + device.id])) {
					name = translations["discipline_device_" + device.id]
				} else {
					name = device.name;
				}
			}
		});
		return name;
	};

	const getNameRegion = (id) => {
		let name = "";
		regions.forEach((region) => {
			if (region.id === id) {
				name = region.name;
			}
		});
		return name;
	};

	const dropdownClicked = (menu) => {
		switch (menu) {
			case "devices":
				regionMenu && setRegionMenu(!regionMenu);
				setDevicesMenu(!devicesMenu);
				break;
			case "region":
				devicesMenu && setDevicesMenu(!devicesMenu);
				setRegionMenu(!regionMenu);
				break;
			default:
				setDevicesMenu(false);
				return;
		}

	};

	const setMenuValues = (devicesSelect, regionSelect) => {
		setDevicesSelect(devicesSelect);
		setRegionSelect(regionSelect);
		onSelectFilter(devicesSelect, regionSelect);
	};

	const handleClickOutside = (event) => {
		if (event.target.closest('.filter-block') === null) {
			setDevicesMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="container filter filter-block">
			<section className="flex-between">

					<div className="sport-filter">
						<span>
							{translations && !isEmpty(translations["filterBy"])
								? translations["filterBy"]
								: "Filter by"
							}
						</span>
							<Fragment>

								<div className="dropdown">
									<button className="dropbtn" onClick={() => dropdownClicked("devices")}>
										{!isEmpty(devicesSelect)
											? getNameDevice(devicesSelect)
											: translations && !isEmpty(translations["devices"])
												? translations["devices"]
												: "Devices"}
									</button>
									{devicesMenu && (
										<ul className="dropdown-content">
											<li onClick={() => setMenuValues("", "")}>
												{translations && !isEmpty(translations["all_filters"])
												? translations["all_filters"]
												: "All"}
											</li>
											{devices &&
											devices.map((device) => {
												return (
													<li onClick={() => setMenuValues(device.id, "")} key={device.id}>
														{
															translations && !isEmpty(translations["discipline_device_"+device.id])
															? translations["discipline_device_"+device.id]
															: removeTags(device.name)
														}
													</li>
												);
											})}
										</ul>
									)}
								</div>
								{!isEmpty(regions) && (
									<div className="dropdown">
									<button className="dropbtn" onClick={() => dropdownClicked("region")}>
									{!isEmpty(regionSelect)
										? getNameRegion(regionSelect)
										: translations && !isEmpty(translations["regions"])
											? translations["regions"]
											: "Regions"}
									</button>
									{regionMenu && devicesSelect && (
										<ul className="dropdown-content">
											<li onClick={() => setMenuValues(devicesSelect, "")}>
												{translations && !isEmpty(translations["all_filters"])
												? translations["all_filters"]
												: "All"}
											</li>
											{regions &&
											regions.map((region, index) => {
												return (
													<li onClick={() => setMenuValues(devicesSelect, region.id)} key={region.id + '_' + index}
													>
														{!isEmpty(region.name) ? removeTags(region.name) : region.id}
													</li>
												);
											})}
										</ul>
									)}
									</div>
								)}
							</Fragment>
					</div>
			</section>
		</div>
	);
};

export default Filter;
