import { combineReducers } from "redux";
import { TOGGLE_MODAL } from "../actions/modal.action";

const initialState = {opened: false, success: true, text: ""};

function toggleReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MODAL:
            return action.payload;
        default:
            return state;
    }
}
export default combineReducers({ toggleReducer });