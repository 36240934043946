import React, {Fragment, useContext, useState} from "react";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {isEmpty} from "../../../Utils";
import {useDispatch, useSelector} from "react-redux";
import {MyContext} from "../../../../contexts/MyContext";
import {getCustomer, getListPaymentsMethods} from "../../../../actions/stripe.action";
import {toggleModal} from "../../../../actions/modal.action";


const EditPayment = ({onClose, onSuccess, setAsDefault = false}) => {

    const { rootState, attachPaymentMethod, updateDefaultPaymentMethod } = useContext(MyContext);
    const { isAuth, theUser } = rootState;
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const customer = useSelector((state) => state.stripeReducer.getCustomerReducer);
    //const [canUpdate, setCanUpdate] = useState(false);
    const [wait, setWait] = useState(false);

    const options = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#666666",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ':-webkit-autofill': {
                    color: "#fce883",
                },
                '::placeholder': {
                    color: "#666666",
                },
            }
        }
    }

    const createMethod = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        if (isAuth) {
            setWait("wait");
            const result = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: theUser.email
                }
            });

            if (!result.error && result.paymentMethod.id) {
                let paymentResult = await attachPaymentMethod(customer.id, result.paymentMethod.id);
                if(paymentResult.result === "ok") {
                    if (setAsDefault) {
                        await setDefault(result.paymentMethod.id);
                    } else {
                        dispatch(getListPaymentsMethods(customer.id));
                        dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
                        onSuccess();
                    }

                    onClose(true);

                } else if (paymentResult.result === "bad" && paymentResult.error) {
                    dispatch(toggleModal({opened: true, success: false, text: paymentResult.error}));
                }
            } else {
                if (result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error.message}));
                }
            }
            setWait(false);
        }
    };

    const setDefault = async (paymentId) => {
        if (isAuth) {
            if (!isEmpty(customer.id) && paymentId) {
                let result = await updateDefaultPaymentMethod(customer.id, paymentId);
                if (result.result === "ok") {
                    dispatch(getCustomer())
                    dispatch(getListPaymentsMethods(customer.id));
                    onClose(true);
                    dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
                    onSuccess();
                } else if (result.result === "bad" && result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error}));
                }
            }
        }
    };

    return (
        <Fragment>
            <div className="background position-fixed top-left bottom-right" style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>
            <div className="absolute-center modal">
                {wait && (
                    <div className="success-animation">
                        <div className="loader">
                            <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                <div className="checkmark draw"/>
                            </div>
                        </div>
                    </div>
                )}

                <div className="p-4">
                    <h3 className="mb-2">Add the payment method</h3>
                    <CardNumberElement options={{...options, iconStyle: "solid", showIcon: true}}/>
                    <div className="d-grid">
                        <CardExpiryElement options={options}/>
                        <CardCvcElement options={options}/>
                    </div>
                    <div className="d-flex mt-4">
                        <button className="btn btn-stripe mr-3"
                                style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                                onClick={() => onClose(true)}
                        >Cancel</button>

                        <button className="btn btn-stripe"
                            //disabled={canUpdate}
                                style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                                onClick={createMethod}
                        >Update</button>
                    </div>
                </div>

            </div>
        </Fragment>

    );
};

export default EditPayment;