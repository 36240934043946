import axios from "axios";

export const GET_INFO = "GET_INFO";

export const getInfo = (infoId) => {
	return async (dispatch) => {
		await axios.get(`${process.env.REACT_APP_API_URL}getInfo&i=${infoId}`)
			.then((res) => dispatch({ type: GET_INFO, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});

	};
};

