import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "../../Utils";
import Summary from "./submenu/Summary";
import Plans from "./submenu/Plans";
import Invoice from "./submenu/Invoice";
import Payment from "./submenu/Payment";

const SubscriptionParam = () => {
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [actualSubMenu, setActualSubMenu] = useState("Summary");
	const [actualSubMenuComponent, setActualSubMenuComponent] = useState(<Summary/>);
	const stripeReducer = useSelector((state) => state.stripeReducer);
	const customer = stripeReducer.getCustomerReducer;

	const changeComponent = useCallback((comp) => {
		setActualSubMenu(comp)
	}, []);

	useEffect(() => {
		switch (actualSubMenu) {
			case "Summary":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					setActualSubMenuComponent(<Summary changeActualComponent={changeComponent}/>);
				}
				break;
			case "Plans":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					setActualSubMenuComponent(<Plans changeActualComponent={changeComponent}/>);
				}
				break;
			case "Invoice":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					setActualSubMenuComponent(<Invoice/>);
				}
				break;
			case "Payment":
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					setActualSubMenuComponent(<Payment/>);
				}
				break;
			default:
				if (!isEmpty(settings) && settings.subscription_features === "yes") {
					setActualSubMenuComponent(<Summary/>);
				}
				break;
		}
	}, [customer, actualSubMenu, changeComponent, settings]);

	return (
		<section className="subscription block">
			<div className="title">
				<h3>
					{translations && !isEmpty(translations["manage_my_subscription"])
						? translations["manage_my_subscription"]
						: "Manage my subscription"
					}
				</h3>
				<ul className="nav nav-tabs">
					<li className="nav-item">
						<span className={actualSubMenu === "Summary" ? "nav-link active" : "nav-link"}
							  onClick={() => {
								  setActualSubMenu("Summary");
							  }}
						>Summary</span>
					</li>
					<li className="nav-item">
						<span className={actualSubMenu === "Plans" ? "nav-link active" : "nav-link"}
							  onClick={() => {
								  setActualSubMenu("Plans");
							  }}
						>Plans</span>
					</li>
					<li className="nav-item">
						<span className={actualSubMenu === "Invoice" ? "nav-link active" : "nav-link"}
							  onClick={() => {
								  setActualSubMenu("Invoice");
							  }}
						>Invoices & Payments</span>
					</li>
					<li className="nav-item">
						<span className={actualSubMenu === "Payment" ? "nav-link active" : "nav-link"}
							  onClick={() => {
								  setActualSubMenu("Payment");
							  }}
						>Payment information</span>
					</li>
				</ul>
			</div>
			<article>
				{customer?.deleted !== true ?
					actualSubMenuComponent
					: <><div>Your stripe account has been deleted.</div><div>Please, contact the administrator</div></>
				}
			</article>
		</section>
	);
};

export default SubscriptionParam;
