import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../components/direct/Banner";
import { isEmpty } from "../components/Utils";
import {getSlideLive, getSlideShow} from "../actions/slideshow.action";
import Events from "../components/Events";
import {getEventsLive} from "../actions/events.action";
import PlaylistEvents from "../components/PlaylistEvents";

const Directs = () => {

	const dispatch = useDispatch();
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);
	const eventsReducer = useSelector((state) => state.eventsReducer.eventsAllReducer);
	const [events, setEvents] = useState([]);
	const [upcoming, setUpcoming] = useState([]);

	useEffect(() => {
		//TODO:: need to change to 1 for get only live
		dispatch(getSlideShow(5, 2));
		dispatch(getSlideLive(5));
		dispatch(getEventsLive(6, 0, 0));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(eventsReducer.live)) {
			setEvents(eventsReducer.live.events);
		}
		if (!isEmpty(eventsReducer.upcoming)) {
			setUpcoming(eventsReducer.upcoming.events);
		}
	}, [eventsReducer]);

	return (
		<main className="explorer">

			<Banner/>

			{!isEmpty(events) &&
				<Events
					events={events}
					title={
						translations && !isEmpty(translations["in_live"])
							? translations["in_live"]
							: "En direct"
					}
					nextPage={eventsReducer.live.nextpage}
					showMore={false}
				/>
			}

			{!isEmpty(upcoming) && (
				<PlaylistEvents
					events={upcoming}
					title={translations && !isEmpty(translations["next_directs"])
						? translations["next_directs"]
						: "Next directs"
					}
					type={2}
					nextPage={eventsReducer.upcoming ? eventsReducer.upcoming.nextpage : false}
				/>
			)}
		</main>
	);
};

export default Directs;
