import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getChildPrograms, getPrograms } from "../actions/programs.action";
import { useParams } from "react-router";
import {isEmpty} from "../components/Utils";
import {Link, useHistory} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const CategoriesProgrammes = () => {
	const { programId } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

	const programReducer = useSelector(
		(state) => state.programReducer.getProgramsReducer
	);

	const programChildReducer = useSelector(
		(state) => state.programReducer.getProgramsChildReducer
	);

	const [selectProgram, setSelectProgram] = useState({
		id: '',
		name: '',
		name_web: '',
		childs: 0 //if childs == 0 -> is last category
	});

	useEffect(() => {
		dispatch(getPrograms(20));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(selectProgram.id)) {
			dispatch(getChildPrograms(20, selectProgram.id));
		}
		// eslint-disable-next-line
	}, [selectProgram]);

	useEffect(() => {
		if (!isEmpty(programReducer.programs) && !isEmpty(programId)) {
			let select = programReducer.programs.filter((prog) => {
				return programId === prog.name_web.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_")
			});
			setSelectProgram(...select);
		}
	}, [programId, programReducer.programs]);

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/`);
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_CLIENT_NAME}</title>
				<meta name="title" content={process.env.REACT_APP_CLIENT_NAME} />
				<meta property="og:title" content={process.env.REACT_APP_CLIENT_NAME} />
				<meta property="og:description" content={process.env.REACT_APP_CLIENT_NAME} />
			</Helmet>

			<main className="categories-page">
				<div className="discipline-title">
					<div className="title">
						<h1>
							<span onClick={() => clickBack()}/>
							{isMobile ? selectProgram.name : selectProgram.name_web}
						</h1>
					</div>
					<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="20%" height="2" fill="#1B3FFF"/>
						<rect x="20%" width="20%" height="2" fill="#D43D17"/>
						<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
						<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
						<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
					</svg>
				</div>

				<div className="categories">
					{!isEmpty(programChildReducer.programs) &&
					programChildReducer.programs.map((category, index) => {
						return (
							<Link
								key={category.id}
								to={`/categories/${programId}/${category.name_web.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_")}`}
								onClick={() => window.scrollTo(0, 0)}
							>
								<div className="category" key={category.id}>
									<div className="image_container">
										<img src={category.pic} alt={isMobile ? category.name : category.name_web}/>
									</div>

									<div className="title">
										<h4>{isMobile ? category.name : category.name_web}</h4>
									</div>
									<div className="play-button">
										<svg className="btn" width="45" height="45" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z" fill="url(#paint0_radial_1332_2732)"/>
											<path className="play_sign" d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z" fill="white"/>
											<defs>
												<radialGradient id="paint0_radial_1332_2732" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18 18) rotate(90) scale(18)">
													<stop stopColor="#4E45AF"/>
													<stop offset="1" stopColor="#362CAE"/>
												</radialGradient>
											</defs>
										</svg>
									</div>
								</div>
							</Link>
						)
					})
					}
				</div>

			</main>
		</>
	);
};

export default CategoriesProgrammes;
