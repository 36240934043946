import {NavLink} from "react-router-dom";
import React from "react";

const SuccessError = (props) => {

    const content = props.content;
    const recoveryPage = props.recoveryPage;

    const close = () => {props.close(false)};
    const sendValidate = () => {props.sendValidate()};

    return (
        <div className="confirm-message display">
            <div className="svg"
                 style={{backgroundColor: content.type === 'fail' ? "rgb(230, 36, 36)" : ''}}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
                </svg>
            </div>
            <h2>{content.title}</h2>
            <p>{content.text}</p>
            {content.sendValidate && (
                <span className="validate_link" onClick={sendValidate}>{content.sendValidateText}</span>
            )}
            {recoveryPage ?
                (
                    <NavLink to="/account/recovery" onClick={() => close()}>
                        <span>{content.textButton}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
                        </svg>
                    </NavLink>
                ) : (
                    <NavLink to="/account/login" onClick={() => close()}>
                        <span>{content.textButton}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
                        </svg>
                    </NavLink>
                )
            }

        </div>
    );
};

export default SuccessError;