import React, {useContext, useEffect, useRef} from "react";
import videojs from "video.js";
import "../videojs/plugins/watermark";
import "../videojs/plugins/pills";
import "../videojs/plugins/sponsors";
import "../videojs/plugins/pre-roll";
import "../videojs/plugins/toasts";
import "../videojs/plugins/resolution-swithcer";
import "../videojs/plugins/fullscreen";
import "../videojs/plugins/subscription";
import "video.js/dist/video-js.css";
import {MyContext} from "../contexts/MyContext";

export const VideoJS = ( props ) => {

    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady, videoId, liveId } = props;
    const { addStatisticRecord } = useContext(MyContext);

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);
            });
        } else {
            // you can update player here [update player through props]
            const player = playerRef.current;
            player.autoplay(options.autoplay);
            player.poster(options.poster);
            player.src(options.sources);
            player.updateSrc(options.additionally);
            player.ads = options.ads;
        }
        // eslint-disable-next-line
    }, [options, videoRef, onReady]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;
        player.fullscreenIphone();

        player.tech_.off('dblclick');
        player.on("resolutionchange", function(){
            updateStatistic()
        });

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    const updateStatistic = () => {
        if (playerRef.current) {
            let resolutions = playerRef.current.currentResolution();
            let resolution = resolutions.sources[0].res;
            addStatisticRecord(videoId, liveId, resolution);
        }
    }

    //playsinline -> disabled opening full screen when user click play on iOS devises
    return (
        <div data-vjs-player>
            <video ref={videoRef} playsInline webkit-playsinline="true" className="video-js vjs-default-skin" />
        </div>
    );
};

export default VideoJS;