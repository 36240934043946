import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {isEmpty} from "../Utils";

const NavBar = () => {

	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;

	return (
		<nav>
			<div
				className="mobile-close"
				onClick={() =>
					document.querySelector(".main-header").classList.remove("menu-open")
				}
			>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 18.8481L8.86739 9.98068L0 1.13645L1.11326 0.0231919L9.98065 8.86742L18.8558 0L19.9691 1.07462L11.0939 9.98068L20 18.9177L18.8867 20.0309L9.98065 11.1249L1.11326 19.9614L0 18.8481Z" fill="white"/>
				</svg>
			</div>
			<ul>
				<li>
					<NavLink
						exact
						to="/"
						onClick={() =>
							document.querySelector(".main-header").classList.remove("menu-open")
						}
					>
						{translations && !isEmpty(translations["menu_home"])
							? translations["menu_home"]
							: "Home"
						}
					</NavLink>
				</li>
				<li>
					<NavLink
						exact
						to="/discipline"
						onClick={() =>
							document.querySelector(".main-header").classList.remove("menu-open")
						}
					>
						{translations && !isEmpty(translations["menu_disciplines"])
							? translations["menu_disciplines"]
							: "Disciplines"
						}
					</NavLink>
				</li>
				<li>
					<NavLink
						exact
						to="/directs"
						onClick={() =>
							document.querySelector(".main-header").classList.remove("menu-open")
						}
					>
						{translations && !isEmpty(translations["menu_directs"])
						? translations["menu_directs"]
						: "Directs"
					}

					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;
