import React, {useEffect, useState} from "react";
import { useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {isEmpty} from "../components/Utils";
import Moment from "react-moment";
import {Helmet} from "react-helmet-async";
import {getInfo} from "../actions/info.action";

const Info = ({ match }) => {

	const {
		params: { infoId },
	} = match;

	const dispatch = useDispatch();
	const history = useHistory();
	const infoReducer = useSelector((state) => state.infoReducer.getInfoReducer);
	const [info, setInfo] = useState([]);

	useEffect(() => {
		dispatch(getInfo(infoId))
	}, [dispatch, infoId]);

	useEffect(() => {
		if (infoReducer.info) {
			setInfo(infoReducer.info)
		}
	}, [infoReducer]);

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/`);
		}
	};

	return (
		<div className="info-page">

			{!isEmpty(infoReducer) && (
				<Helmet>
					<title>{`${process.env.REACT_APP_CLIENT_NAME}`}</title>
					<meta name="title" content={`${process.env.REACT_APP_CLIENT_NAME}`} />
					<meta property="og:title" content={`${process.env.REACT_APP_CLIENT_NAME}`} />
					{/*<meta property="og:description" content={`${video.description}`} />
					<meta property="og:image" content={`${video.poster}`} />*/}
				</Helmet>
			)}

			<div className="container">
				<span className="back" onClick={() => clickBack()}/>
				<div className="info-data">
					<div className="image-block">
						<img src={info.image} alt={`Card ${info.name}`}/>
					</div>
					<h2>{info.name}</h2>
					<div className="views">
						<Moment format="DD/MM/YYYY">{info.added}</Moment>
					</div>
					<div className="description">{info.content}</div>
					<div className="clear"/>
				</div>
			</div>

		</div>
	);
};

export default Info;