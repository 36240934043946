import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {isEmpty} from "./Utils";
import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import moment from "moment";
import {MyContext} from "../contexts/MyContext";
import {getVideoFavorite} from "../actions/videos.action";
import {toggleModal} from "../actions/modal.action";

const PlaylistFavoriteVideos = (props) => {
	const { name, videos, hideSeeMore, showCount, nextPage } = props;
	const sliderRef = useRef(null);
	const slider = useRef();
	const dispatch = useDispatch();
	const [width, setWidth] = useState();
	const [videoArray, setVideoArray] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(5);
	const [hasNexPage, setHasNextPage] = useState(nextPage);
	const [countPage, setCountPage] = useState(1);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const { rootState, addToVideoFavorite } = useContext(MyContext);
	const { isAuth } = rootState;

	const getWidth = () => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			const newWidth = sliderRef.current.clientWidth;
			setWidth(newWidth);
		}
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 150);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			getWidth();
		}
	}, []);

	useEffect(() => {
		window.addEventListener("resize", getWidth);
		return () => {
			window.removeEventListener("resize", getWidth);
		};
	}, []);

	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: itemsPerPage,
		slidesToScroll: itemsPerPage,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		draggable: true,
		initialSlide: 0,
		centerMode: false,
		afterChange: (index) => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				nextPageHandler();
			}
		}
	};

	useEffect(() => {
		setVideoArray(videos)
	}, [videos]);

	useEffect(() => {
		if (!isEmpty(width) && Math.floor(width / 200) <= 5) {
			setItemsPerPage(Math.floor(width / 200));
		} else {
			setItemsPerPage(5);
		}
	}, [width]);

	const nextPageHandler = async () => {
		if (hasNexPage !== null) {
			const loginToken = localStorage.getItem("loginToken");
			if (loginToken) {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("p", countPage.toString());
				form.append("t", "3");
				form.append("n", "10");
				await axios.post(`${process.env.REACT_APP_API_URL}getVideoFavorite`, form)
					.then((res) => {
						setHasNextPage(res.data.nextpage);
						setCountPage(countPage + 1);
						setVideoArray([...videoArray, ...res.data.videos]);
					})
					.catch((error) => {
						console.log(error)
					});
			}
		}

	};

	const handleFavorite = async (id, keyArray) => {

		await addToVideoFavorite(id)
			.then((res) => {
				if (res.result === "ok") {
					const arrayClone = Array.from(videoArray);
					arrayClone.splice(keyArray, 1)
					setVideoArray(arrayClone);
					if (slider) {
						slider.current.slickGoTo(0);

						let text = translations && !isEmpty(translations["video_removed_from_list"])
							? translations["video_removed_from_list"]
							: "Your video has been removed from your list!"

						dispatch(toggleModal(
							{
								opened: true,
								success: true,
								text: text
							})
						)
					}
					if (isAuth) {
						dispatch(getVideoFavorite("0", "3", "10"))
					}
				}
			})
	};

	return (
		<div className="playlist-videos filter white">
			<div className="titles">
				<section
					className="flex-between m-auto"
				>
					<h3>
						{!isEmpty(name) && name}
						{!isEmpty(showCount) && (
							<span className="count">{showCount}</span>
						)}
					</h3>
					{!hideSeeMore && (
						<NavLink to="/videos" className="item-menu">
							<h5>
								{translations && !isEmpty(translations["see_more"])
									? translations["see_more"]
									: "See more"
								}
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.665363 6.66536L8.7787 6.66536L5.05203 10.392L5.9987 11.332L11.332 5.9987L5.9987 0.665364L5.0587 1.60536L8.7787 5.33203L0.665363 5.33203L0.665363 6.66536Z" fill="#F41B3B" stroke="#F41B3B" strokeWidth="0.2"/>
								</svg>
							</h5>
						</NavLink>
					)}
				</section>
			</div>
			<div ref={sliderRef}>
				<Slider ref={slider} {...settings} className="videos-container hide-scroll customScroll">
					{!isEmpty(videoArray) &&
						videoArray.map((video, key) => {
							return (
								<div className="video" key={video.id}>
									<span className="cross" onClick={() => handleFavorite(video.id, key)}>
										<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 5L5 9.5M5 5L9.5 9.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M7.25 13.5C10.7018 13.5 13.5 10.7018 13.5 7.25C13.5 3.79822 10.7018 1 7.25 1C3.79822 1 1 3.79822 1 7.25C1 10.7018 3.79822 13.5 7.25 13.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
										<span>
											{translations && !isEmpty(translations["remove_from_my_list"])
												? translations["remove_from_my_list"]
												: "Remove from my list"
											}
										</span>
									</span>
									<Link
										draggable="false"
										rel="noopener noreferrer"
										to={`/video/${video.id}`}
										onClick={() => window.scrollTo(0, 0)}
									>
										<img
											className="image"
											src={video.image_desktop}
											alt={`Apercu de ${video.name}`}
											width="100"
											height="66"
										/>
										{!isEmpty(video.payable) && video.payable && (
											<svg className="payable" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.6 257.6"><path fill="#fff" d="M1452.7,590.5l-.3-14c-10-.2-19.6-2.6-25.3-5.8l4-17.3a53.3,53.3,0,0,0,24.7,5.9c8.4-.2,14.1-3.6,14-9.5s-5-9-16-12.5c-15.9-4.9-26.9-12.1-27.2-26.4s8.6-23.4,24.4-26.9l-.4-14,14.5-.4.3,13c9.9.3,16.6,2.2,21.5,4.4l-3.9,16.8c-3.8-1.5-10.7-4.8-21.3-4.5s-12.7,4.4-12.6,8.6,5.4,7.8,18.1,12.3c17.7,5.7,25,13.7,25.3,27s-8.8,24.6-25.7,27.9l.4,15.1Z" transform="translate(-1330.3 -401.3)"/></svg>
										)}
										<div className="title-container">
											<div className="play">
												<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
													 fill="none" xmlns="http://www.w3.org/2000/svg">
													<path className="round"
														  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
														  fill="#362CAE"/>
													<path className="play_sign"
														  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
														  fill="white"/>
												</svg>
											</div>
											<div className="video-date">
												<div className="line">
													<div className="line">
														<svg className="bar" width="100" height="4" viewBox="0 0 100 4" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect width="100" height="4" rx="2" fill="url(#paint0_radial_1607_3199)"/>
															<defs>
																<radialGradient id="paint0_radial_1607_3199" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 2) rotate(90) scale(2 27)">
																	<stop stopColor="#4E45AF"/>
																	<stop offset="1" stopColor="#362CAE"/>
																</radialGradient>
															</defs>
														</svg>
													</div>
												</div>
												<div className="date">
													{video.addedon && moment(video.addedon).format("DD/MM/YYYY")}
												</div>
											</div>
											<h4>{video.name}</h4>
											<div className="video-description">
												{!isEmpty(video.descr) && video.descr.length > 180 ? video.descr.slice(0,180) + "..." : video.descr}
											</div>
										</div>
									</Link>
								</div>
							);
						})}
				</Slider>
			</div>
		</div>
	);
};

export default PlaylistFavoriteVideos;
