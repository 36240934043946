import React from "react";
import { NavLink } from "react-router-dom";
import AccountSection from "./header/AccountSection";
import NavBar from "./header/NavBar";
import {useMediaQuery} from "react-responsive";
import NavBarMobile from "./header/NavBarMobile";

const Header = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	return (
		<header className="main-header">
			<div className="hide-background"/>
			<div className="logo">
				<NavLink exact to="/">
					<img
						src={`${process.env.REACT_APP_CACHE_SERVER}/images/logo.png`}
						alt="icon"
					/>
				</NavLink>
			</div>

			{isMobile ? <NavBarMobile /> : <NavBar />}

			<AccountSection />

			<div
				className="mobile-menu"
				onClick={() =>
					document.querySelector(".main-header").classList.add("menu-open")
				}
			>
				<svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M29.25 0H0.75C0.335786 0 0 0.335786 0 0.75C0 1.16421 0.335786 1.5 0.749999 1.5H29.25C29.6642 1.5 30 1.16421 30 0.75C30 0.335786 29.6642 0 29.25 0Z" fill="white"/>
					<path d="M18.25 9H0.75C0.335786 9 0 9.33579 0 9.75C0 10.1642 0.335787 10.5 0.750001 10.5H18.25C18.6642 10.5 19 10.1642 19 9.75C19 9.33579 18.6642 9 18.25 9Z" fill="white"/>
					<path d="M29.25 17.5996H0.75C0.335786 17.5996 0 17.9354 0 18.3496C0 18.7638 0.335786 19.0996 0.749999 19.0996H29.25C29.6642 19.0996 30 18.7638 30 18.3496C30 17.9354 29.6642 17.5996 29.25 17.5996Z" fill="white"/>
				</svg>

			</div>

			{isMobile && (
				<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg" className="border">
					<rect width="20%" height="2" fill="#1B3FFF"/>
					<rect x="20%" width="20%" height="2" fill="#D43D17"/>
					<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
					<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
					<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
				</svg>
			)}

		</header>
	);
};

export default Header;
