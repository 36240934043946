export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const toggleModal = (state) => {

    return (dispatch) => {
        dispatch({ type: TOGGLE_MODAL, payload: state });

        if (state.opened === true) {
            setTimeout(() => {
                dispatch({ type: TOGGLE_MODAL, payload: {opened: false, success: true, text: ""}});
            }, 2000)
        }

    };
};