import Routes from "./components/Routes/Routes";
//HELMET
import { Helmet } from "react-helmet-async";
import { useMatomo } from '@datapunt/matomo-tracker-react'
//CACHE
import withClearCache from "./ClearCache";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSetting} from "./actions/setting.action";
import {isEmpty} from "./components/Utils";
import ToastModal from "./components/ToastModal";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {
	const dispatch = useDispatch();
	const { trackPageView } = useMatomo();
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);

	useEffect(() => {
		dispatch(getSetting());

		if (process.env.REACT_APP_ENV === "production" && navigator.userAgent !== "ReactSnap") {
			trackPageView()
		}
		// eslint-disable-next-line
	}, []);

	return (
		<main className="App" style={{background: !isEmpty(settings) ? settings.background_color : ""}}>
			<Helmet>
				<title>{process.env.REACT_APP_CLIENT_NAME}</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/images/logo.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>
			<ToastModal/>
			<Elements stripe={stripePromise}>
				<Routes />
			</Elements>

		</main>
	);
}

export default App;
