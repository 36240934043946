import React, {Fragment, useContext, useState} from "react";
import { useSelector } from "react-redux";
import { MyContext } from "../../contexts/MyContext";
import {isEmpty} from "../Utils";

const Avatar = () => {
	const { rootState, updateAvatar, isLoggedIn } = useContext(MyContext);
	const { theUser } = rootState;
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);
	const [upload, setUpload] = useState(false);
	const uploadAvatar = async (file) => {
		const formData = new FormData();
		formData.append("avatar", file);
		setUpload(true);
		const data = await updateAvatar(formData);
		if (data.result === "ok") {
			await isLoggedIn();
			if (document.querySelector("#avatar #validUpdate")) {
				setTimeout(() => {
					document.querySelector("#avatar #validUpdate").innerHTML = translations
					&& !isEmpty(translations["avatar_good"])
						? translations["avatar_good"]
						: "Updated profile picture!";
					document
						.querySelector("#avatar #validUpdate")
						.classList.add("great", "display");
                    setUpload(false);
				}, 10);

				setTimeout(() => {
					document
						.querySelector("#avatar #validUpdate")
						.classList.remove("great", "display");
				}, 3000);
			}
		} else {
			setTimeout(() => {
				document.querySelector("#avatar #validUpdate").innerHTML = translations && !isEmpty(translations["error_occur"])
					? translations["error_occur"]
					: "An unexpected error occured";
				document
					.querySelector("#avatar #validUpdate")
					.classList.add("error", "display");
                setUpload(false);
			}, 10);

			setTimeout(() => {
				document
					.querySelector("#avatar #validUpdate")
					.classList.remove("error", "display");
			}, 3000);
			console.log(data.message);
		}
	};

	return (
		<div id="avatar">

			{!isEmpty(theUser) && (
				<Fragment>
					<h4>
						{translations && !isEmpty(translations["avatar"])
							? translations["avatar"]
							: "avatar"
						}
					</h4>
					<div className="data">
						<div className="image">
							<img
								src={
									upload ? "/img/footages/preloader.gif" : (!isEmpty(theUser.img.avatar) ? theUser.img.avatar : "/img/icons/user.svg")
								}
								alt="account" width="70"
							/>
							<form>
								<input
									type="file"
									name="avatarUpload"
									id="avatarUpload"
									onChange={(e) => uploadAvatar(e.target.files[0])}
								/>
								<label htmlFor="avatarUpload">
									<svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M3.54887 20.5342L22.0365 20.6193C24.3128 20.6298 25.5386 19.4152 25.549 17.1593L25.5986 6.38259C25.609 4.12675 24.3944 2.90094 22.1181 2.89046L19.4213 2.87805C18.6318 2.87441 18.3555 2.75009 17.8349 2.22474L17.0287 1.39046C16.4776 0.823951 15.9354 0.554851 14.8997 0.550085L10.829 0.53135C9.79335 0.526584 9.24868 0.790682 8.69238 1.35209L7.8785 2.17892C7.35315 2.69946 7.07573 2.82123 6.28619 2.8176L3.63046 2.80537C1.35412 2.7949 0.128298 4.00948 0.117916 6.26532L0.0683182 17.0421C0.0579363 19.2979 1.27252 20.5237 3.54887 20.5342ZM3.775 18.1871C2.89318 18.183 2.41332 17.7296 2.41762 16.7965L2.46486 6.53247C2.46915 5.59938 2.95315 5.15043 3.83498 5.15448L7.01366 5.16911C7.9365 5.17336 8.38838 5.02163 8.91391 4.46008L9.67647 3.64327C10.2535 3.04094 10.5721 2.87834 11.4642 2.88244L14.2429 2.89523C15.135 2.89934 15.4521 3.06486 16.0031 3.66213L16.7786 4.49627C17.2989 5.06264 17.7494 5.21852 18.6722 5.22277L21.8919 5.23759C22.7737 5.24165 23.2639 5.69508 23.2596 6.62817L23.2123 16.8922C23.208 17.8253 22.7138 18.2742 21.8319 18.2702L3.775 18.1871ZM12.8144 16.9674C15.8495 16.9814 18.3012 14.5522 18.3152 11.4966C18.3293 8.44095 15.9104 5.98936 12.865 5.97535C9.81961 5.96133 7.36798 8.39051 7.35391 11.4461C7.33985 14.5018 9.76902 16.9534 12.8144 16.9674ZM18.6291 7.90912C18.6256 8.67816 19.2483 9.28601 20.0071 9.27925C20.7556 9.28269 21.3839 8.6806 21.3874 7.92182C21.3908 7.18354 20.758 6.54488 20.0197 6.54149C19.2609 6.53799 18.6325 7.17085 18.6291 7.90912ZM12.824 14.8756C10.9476 14.867 9.42675 13.3424 9.43544 11.4557C9.44412 9.56902 10.9687 8.05844 12.8554 8.06712C14.7318 8.07576 16.2424 9.60031 16.2337 11.487C16.225 13.3737 14.7005 14.8843 12.824 14.8756Z" fill="white"/>
									</svg>

								</label>
								<div id="validUpdate" className="great"/>
							</form>
						</div>
					</div>
				</Fragment>
			)}

		</div>
	);
};

export default Avatar;
