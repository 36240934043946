import React from "react";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {isEmpty} from "./Utils";
import {Link, NavLink} from "react-router-dom";

const SliderStyle = (props) => {
	const { slide, styleProp } = props;

	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);

	const translations = translationsReducer.translate;
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });


	return (
		<section data-slide={`slide-${slide.id}`} className={`slide display-2`}>
			<div className="title">
				<div className="main-title">
					<h1>
						{slide.title}
					</h1>
				</div>
				<div className="buttons">
					{slide.type === "live" && (
						<NavLink exact to={`/${slide.type}/${slide.typeid}`} className="button button-template mr-2">{
							translations && !isEmpty(translations["watch_live_stream"])
								? translations["watch_live_stream"]
								: "Regarder le direct"
						}</NavLink>
					)}
					{slide.type === "video" && (
						<NavLink exact to={`/${slide.type}/${slide.typeid}`} className="button button-template mr-2">{
							translations && !isEmpty(translations["watch_video"])
								? translations["watch_video"]
								: "Regarder le video"
						}</NavLink>
					)}
					{slide.type === "info" && (
						<NavLink exact to={`/${slide.type}/${slide.typeid}`} className="button button-grey mr-2">{
							translations && !isEmpty(translations["more_info"])
							? translations["more_info"]
							: "Plus d’infos"
						}</NavLink>
					)}
				</div>
			</div>
			{styleProp === "2" && slide.type === "live" && (
				<Link to={`/${slide.type}/${slide.typeid}`}>
					<div className="play">
						<svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M25.6641 17.5821L3.43494 31.2732C2.56791 31.8072 1.43552 31.5318 0.905677 30.6579C0.729148 30.3668 0.635742 30.0322 0.635742 29.691V2.30884C0.635742 1.28477 1.45946 0.45459 2.47556 0.45459C2.8141 0.45459 3.14607 0.548728 3.43494 0.726642L25.6641 14.4177C26.5311 14.9517 26.8045 16.093 26.2746 16.9668C26.1224 17.2178 25.9132 17.4288 25.6641 17.5821Z" fill="white"/>
						</svg>
					</div>
				</Link>
			)}
			<img src={!isMobile ? slide.pic_desktop : slide.pic} width="100" alt="banner slide" />
			<div className="dots">
				<img src="/img/decorations/banner/banner_transparent.png" alt="decoration elements" />
			</div>
		</section>
	);
};

export default SliderStyle;
