import axios from "axios";

export const GET_FILTER = "GET_FILTER";

export const getFilter = (from = "discipline", id = 0, disId = 0) => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getfilters&f=${from}&i=${id}&d=${disId}`)
			.then((res) => dispatch({ type: GET_FILTER, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});
	};
};