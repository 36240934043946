import React, { Fragment, useEffect, useState } from "react";
import {isEmpty, removeTags} from "../Utils";
import { useDispatch, useSelector } from "react-redux";
import {getFilter} from "../../actions/filter.action";

const Filter = (props) => {
	const onSelectFilter = props.onSelectFilter;
	const dispatch = useDispatch();
	const filterReducer = useSelector((state) => state.filterReducer.getFilterReducer);

	const [disciplines, setDisciplines] = useState([]);
	const [disciplineMenu, setDisciplineMenu] = useState(false);
	const [disciplineSelect, setDisciplineSelect] = useState("");

	const [devices, setDevices] = useState([]);
	const [devicesMenu, setDevicesMenu] = useState(false);
	const [devicesSelect, setDevicesSelect] = useState("");

	const [regions, setRegions] = useState([]);
	const [regionMenu, setRegionMenu] = useState(false);
	const [regionSelect, setRegionSelect] = useState("");

	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const translations = translationsReducer.translate;

	useEffect(() => {
		if (!isEmpty(filterReducer.filters)) {
			setDevices(filterReducer.filters.device);
			setDisciplines(filterReducer.filters.discipline);
			setRegions(filterReducer.filters.regions);
		}
	}, [filterReducer]);

	useEffect(() => {
		setDisciplineMenu(false);
		setDevicesMenu(false);
		setRegionMenu(false);
	}, [disciplineSelect, devicesSelect, regionSelect]);


	useEffect(() => {
		dispatch(getFilter("all"));
	}, [dispatch]);

	const getNameDiscipline = (id) => {
		let name = "";
		disciplines.forEach((discipline) => {
			if (discipline.id === id) {
				if(!isEmpty(translations["discipline_" + discipline.id]))
				{
					name = translations["discipline_" + discipline.id]
				} else {
					name = discipline.name;
				}
			}
		});
		return name;
	};

	const getNameDevice = (id) => {
		let name = "";
		devices.forEach((device) => {
			if (device.id === id) {
				if(!isEmpty(translations["discipline_device_" + device.id]))
				{
					name = translations["discipline_device_" + device.id]
				} else {
					name = device.name;
				}
			}
		});
		return name;
	};

	const getNameRegion = (id) => {
		let name = "";
		regions.forEach((region) => {
			if (region.id === id) {
				name = region.name;
			}
		});
		return name;
	};

	const dropdownClicked = (menu) => {
		switch (menu) {
			case "disciplines":
				devicesMenu && setDevicesMenu(!devicesMenu);
				regionMenu && setRegionMenu(!regionMenu);
				setDisciplineMenu(!disciplineMenu);
				break;
			case "devices":
				disciplineMenu && setDisciplineMenu(!disciplineMenu);
				regionMenu && setRegionMenu(!regionMenu);
				setDevicesMenu(!devicesMenu);
				break;
			case "region":
				devicesMenu && setDevicesMenu(!devicesMenu);
				disciplineMenu && setDisciplineMenu(!disciplineMenu);
				setRegionMenu(!regionMenu);
				break;
			default:
				setDevicesMenu(false);
				setDisciplineMenu(false);
				setRegionMenu(false);
				return;
		}

	};

	const setMenuValues = (disciplineSelect, devicesSelect, regionSelect) => {
		setDisciplineSelect(disciplineSelect);
		setDevicesSelect(devicesSelect);
		setRegionSelect(regionSelect);
		onSelectFilter(disciplineSelect, devicesSelect, regionSelect);
	};

	const handleClickOutside = (event) => {
		if (event.target.closest('.filter-block') === null) {
			setDevicesMenu(false);
			setDisciplineMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="container filter filter-block">
			<section className="flex-between">

					<div className="sport-filter">
						<span>
							{translations && !isEmpty(translations["filterBy"])
								? translations["filterBy"]
								: "Filter by"
							}
						</span>
							<Fragment>
								<div className="dropdown">
									<button
										className="dropbtn"
										onClick={() => dropdownClicked("disciplines")}
									>
										{
											!isEmpty(disciplineSelect) ? getNameDiscipline(disciplineSelect)
												: translations && !isEmpty(translations["discipline"])
												? translations["discipline"]
												: "discipline"
										}
									</button>
									{disciplineMenu && (
										<ul className="dropdown-content">
											<li onClick={() => setMenuValues("", "", "")}>
												{
													translations && !isEmpty(translations["all_filters"])
														? translations["all_filters"]
														: "All"
												}
											</li>
											{disciplines &&
											disciplines.map((discipline, index) => {
												return (
													<li
														onClick={() => setMenuValues(discipline.id, "", "")}
														key={discipline.id + '_' + index}
													>
													{
														translations && !isEmpty(translations["discipline_"+discipline.id])
															? translations["discipline_"+discipline.id]
															: removeTags(discipline.name)
													}
													</li>
												);
											})}
										</ul>
									)}
								</div>

								<div className="dropdown">
									<button className="dropbtn" onClick={() => dropdownClicked("devices")}>
										{
											!isEmpty(devicesSelect) ? getNameDevice(devicesSelect)
												: translations && !isEmpty(translations["devices"])
												? translations["devices"]
												: "Devices"
										}
									</button>
									{devicesMenu && disciplineSelect && (
										<ul className="dropdown-content">
											<li onClick={() => setMenuValues(disciplineSelect, "", "")}>
												{
													translations && !isEmpty(translations["all_filters"])
														? translations["all_filters"]
														: "All"
												}
											</li>
											{devices &&
											devices.map((device, index) => {
												return (
													<li onClick={() => setMenuValues(disciplineSelect, device.id, "")} key={device.id + '_' + index}
													>
														{
															translations && !isEmpty(translations["discipline_device_"+device.id])
																? translations["discipline_device_"+device.id]
																: removeTags(device.name)
														}
													</li>
												);
											})}
										</ul>
									)}
								</div>
								<div className="dropdown">
									<button className="dropbtn" onClick={() => dropdownClicked("region")}>
										{
											!isEmpty(regionSelect) ? getNameRegion(regionSelect)
												: translations && !isEmpty(translations["regions"])
												? translations["regions"]
												: "Regions"
										}
									</button>
									{regionMenu && disciplineSelect && devicesSelect && (
										<ul className="dropdown-content">
											<li onClick={() => setMenuValues(disciplineSelect, devicesSelect, "")}>
												{
													translations && !isEmpty(translations["all_filters"])
														? translations["all_filters"]
														: "All"
												}
											</li>
											{regions &&
											regions.map((region, index) => {
												return (
													<li onClick={() => setMenuValues(disciplineSelect, devicesSelect, region.id)} key={region.id + '_' + index}
													>
														{!isEmpty(region.name) ? removeTags(region.name) : region.id}
													</li>
												);
											})}
										</ul>
									)}
								</div>
							</Fragment>
					</div>
			</section>
		</div>
	);
};

export default Filter;
