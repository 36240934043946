import React, {Fragment} from "react";
import Select from "react-select";
import {isEmpty} from "../Utils";
import {useSelector} from "react-redux";

const PriceSelector = ({wait, prices, setCheckedPriceId, closeModal, onChangePlan, styles}) => {

    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            borderRadius: "5px",
            border: "1px solid #8d8d8d",
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "2.5rem",
            color: "#ffffff",
            boxShadow: "none",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
            fontSize: "1.6rem"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };

    return (
        <Fragment>
            <div className="background position-fixed top-left bottom-right" style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>

            <div className="absolute-center modal" style={styles}>
                {wait && (
                    <div className="success-animation">
                        <div className="loader">
                            <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                <div className="checkmark draw"/>
                            </div>
                        </div>
                    </div>
                )}
                <h3 className="p-4">Choose a subscription type</h3>
                <div className="mx-4">
                    <Select
                        styles={customStyles}
                        options={prices}
                        onChange={(option) => setCheckedPriceId(option.value)}
                    />
                </div>

                <div className="d-flex float-right mt-4 mr-4 mb-2">
                    <button className="btn btn-stripe mr-3"
                            onClick={closeModal}
                            style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                    >Cancel</button>

                    <button className="btn btn-stripe"
                            onClick={onChangePlan}
                            style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                    >Update</button>
                </div>
            </div>


        </Fragment>
    );
}

export default PriceSelector;