import videojs from 'video.js';

const Plugin = videojs.getPlugin('plugin');

class Pills extends Plugin {
    constructor(player, options) {
        super(player, options);
        this.videoEl = player.el();
        this.index = 0;
        this.on("statechanged", () => this.startShow(null));
    }

    dispose() {
        this.pillsStop();
        super.dispose();
    }

    updateState = (settings) => {
        this.setState(settings);

        //disabled if has olded
        this.player.off("play", this.startShow);
        this.player.off("pause", this.pillsStop);

        if (settings.images && settings.images.length > 0) {
            this.player.on("play", () => {
                if (this.player.preroll().ended) this.startShow(null)
            });
            this.player.on("pause", this.pillsStop);
        }

    };

    pillsStop = () => {

        this.index = 0;
        if (this.pillsInterval) {
            clearInterval(this.pillsInterval);
        }

        if (this.pillsTimeout) {
            clearTimeout(this.pillsTimeout);
        }

        if (this.divElem) {
            this.divElem.remove();
        }
    };


    createDiv = (startIndex) => {

        if (!this.state.images || this.state.images.length < 1) {
            this.pillsStop();
            return;
        }

        if (this.divElem) {
            this.divElem.remove();
        }

        if (typeof this.state.images[startIndex] !== 'undefined') {

            this.divElem = document.createElement('div');
            this.img = document.createElement('img');
            this.divElem.classList.add('vjs-pills-content');
            this.divElem.classList.add("animate__animated", this.state.show_animation);
            this.img.src = this.state.images[startIndex].image;

            if (this.state.images[startIndex].link) {
                this.a = document.createElement('a');
                this.a.href = this.state.images[startIndex].link;
                this.a.onclick = (e) => {
                    e.preventDefault();
                    this.player.pause();
                    window.open(this.state.images[startIndex].link);
                };
                this.a.appendChild(this.img);
                this.divElem.appendChild(this.a);
            } else {
                this.divElem.appendChild(this.img);
            }

            this.pillsTimeout = setTimeout(() => {
                if (this.divElem.classList.contains(this.state.show_animation)) {
                    this.divElem.classList.remove(this.state.show_animation);
                }
                if (!this.divElem.classList.contains(this.state.hide_animation)) {
                    this.divElem.classList.add(this.state.hide_animation);
                }
            }, this.state.secondary_display_time * 1000);


            this.videoEl.appendChild(this.divElem);

        }

        this.index ++;

    };

    startShow = (startIndex = null) => {

        if (!this.player.preroll().ended) {
            return
        }

        if (startIndex === null) {
            this.createDiv(0);
        }

        if (this.pillsInterval) {
            clearInterval(this.pillsInterval);
        }

       this.pillsInterval = setInterval(() => {

            if (this.divElem) {
                this.divElem.remove(); //remove old
            }

            if (this.index >= this.state.images.length) {
                this.index = 0;
                clearInterval(this.pillsInterval);
                this.startShow(0);
            } else {
                this.createDiv(this.index);
            }

        }, this.state.secondary_times * 60000);
    };


}

videojs.registerPlugin('pills', Pills);