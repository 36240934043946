import React, {useContext, useEffect, useState} from "react";
import { useSelector} from "react-redux";
import {Redirect} from "react-router";
import {NavLink} from "react-router-dom";
import {isEmpty} from "../components/Utils";
import {MyContext} from "../contexts/MyContext";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import SuccessError from "../components/account/SuccessError";

const Account = ({ match }) => {
	//GET DATA
	const {
		params: { method },
	} = match;

	const availableMethods = [
		"login",
		"register",
		"forgot",
		"recovery",
		"verification",
		undefined,
	];
	const { registerUser, loginUser, isLoggedIn } = useContext(MyContext);
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [hover,setHover] = useState(false);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const selectedLanguage = translationsReducer.language;
	const translations = translationsReducer.translate;

	const [email, setEmail] = useState(null);
	const [username, setUsername] = useState(null);
	const [password, setPassword] = useState(null);
	const [passwordVerify, setPasswordVerify] = useState(null);
	const [captchaValue, setCaptchaValue] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [popup, setPopup] = useState(false);
	const [firstState, setFirstState] = useState(true);

	const [redirect, setRedirect] = useState(false);
	const [errorEmailLogin, setErrorEmailLogin] = useState(false);
	const [errorPasswordLogin, setErrorPasswordLogin] = useState(false);

	const [errorMail, setErrorMail] = useState(false);
	const [errorMailUsed, setErrorMailUsed] = useState(false);
	const [errorUserName, setErrorUserName] = useState(false);
	const [errorUserNameUsed, setErrorUserNameUsed] = useState(false);
	const [errorPassword, setErrorPassword] = useState(false);
	const [errorPasswordVerify, setErrorPasswordVerify] = useState(false);
	const [capital, setCapital] = useState(false);
	const [letterNumber, setLetterNumber] = useState(false);
	const [formCompleted, setFormCompleted] = useState(null);


	const [popupContent, setPopupContent] = useState({
		title: "",
		text: "",
		textButton: "",
		type: "",
	});

	const initialState = {
		userInfo: {
			email: "",
			password: "",
		},
		errorMsg: "",
		successMsg: "",
	};
	const [state, setState] = useState(initialState);

	useEffect(() => {
		setPassword(null);
		setPasswordVerify(null);
		setUsername(null);
		setEmail(null);
		setCaptchaValue(null);
		setCapital(false);
		setLetterNumber(false)
	}, [method]);

	useEffect(() => {
		if (!isEmpty(username) && !isEmpty(email) && !isEmpty(password) && !isEmpty(passwordVerify) && !isEmpty(selectedLanguage) && !errorMail && !errorUserName && !errorPassword && !errorPasswordVerify && capital && letterNumber && captchaValue !== null) {
			setFormCompleted(true);
		}
		else {
			setFormCompleted(false);
		}
	}, [username, email, password, passwordVerify, selectedLanguage, errorMail, errorUserName, errorPassword, errorPasswordVerify, capital, letterNumber, captchaValue]);
	useEffect(() => {
		if (popup) {
			document.querySelector(".hide-background").classList.add("hover");
			switch (popup) {
				case 1:
					//register success
					setPopupContent({
						title: "Inscription Confirmée",
						text: "",
						textButton: "Ok, me connecter",
						type: "success",
					});
					break;
				case 2:
					//send mail success
					setPopupContent({
						title: "Confirmée",
						text: translations && !isEmpty(translations["emailSendSuccess"])
							? translations["emailSendSuccess"]
							: "",
						textButton: "Login",
						type: "success",
					});
					break;
				case 3:
					//send mail fail
					setPopupContent({
						title: "Fail",
						text: translations && !isEmpty(translations["emailSendFail"])
							? translations["emailSendFail"]
							: "",
						textButton: "Login",
						type: "fail",
					});
					break;
				case 4:
					setPopupContent({
						title: "Confirmée",
						text: translations && !isEmpty(translations["passChangedSuccess"])
							? translations["passChangedSuccess"]
							: "",
						textButton: "Login",
						type: "success",
					});
					break;
				case 5:
					setPopupContent({
						title: "Confirmée",
						text: translations && !isEmpty(translations["recoveryMailIsSend"])
							? translations["recoveryMailIsSend"]
							: "Nous vous avons envoyé un e-mail contenant un code",
						textButton: "Login",
						type: "success",
					});
					break;
				case 8:
					setPopupContent({
						title: "Fail",
						text: translations && !isEmpty(translations["resetLinkFail"])
							? translations["resetLinkFail"]
							: "",
						textButton: "Login",
						type: "fail",
					});
					break;
				default:
					return false;
			}
		} else {
			setPopupContent({ title: "", text: "", textButton: "", type: "" });
			document.querySelector(".hide-background").classList.remove("hover");
		}
	}, [popup, translations]);

	///START ERRORS
	useEffect(() => {
		if (method === "register") {
			setErrorUserName(false);
			setErrorUserNameUsed(false);
			setErrorMailUsed(false);
			if (!isEmpty(username)) {
				if (username.length < 2 || !username.match(/^[a-zA-Z0-9_]*$/)) {
					setErrorUserName(true);
				} else {
					setErrorUserName(false);
					setErrorUserNameUsed(false);
					setErrorMailUsed(false);
				}
			}
			setFirstState(false);
		}
	}, [username, method, firstState]);

	useEffect(() => {
		if (method === "register" || method === "forgot") {
			setErrorMail(false);
			if (!isEmpty(email)) {
				email.length < 2 ||
				!email.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
					? setErrorMail(true)
					: setErrorMail(false);
			}
		}
	}, [email, method]);

	useEffect(() => {
		if (method === "register" || method === "forgot" || method === "recovery") {
			if (!isEmpty(password)) {
				password.length >= 8 ? setLetterNumber(true) : setLetterNumber(false);
				password.match(/^(?=.*[A-Z]).{8,}$/)
					? setCapital(true)
					: setCapital(false);

				if (password.length < 8 || !password.match(/^(?=.*[A-Z]).{8,}$/)) {
					setErrorPassword(true);
				} else {
					setErrorPassword(false);
				}
			}

			if (!isEmpty(password) && !isEmpty(passwordVerify)) {
				passwordVerify !== password
					? setErrorPasswordVerify(true)
					: setErrorPasswordVerify(false)
			}
		}
	}, [password, passwordVerify, method]);
	//END ERRORS

	/////////////////////
	//Recovery password//
	/////////////////////
	const sendRecovery = async (event) => {
		event.preventDefault();
		if (isEmpty(event.target.email.value) || captchaValue === null) {
			return false;
		} else {
			let form = new FormData();
			form.append("e", event.target.email.value);
			let result = await axios.post(
				`${process.env.REACT_APP_API_URL}recover`, form
			);
			if (result.data.result === "ok") {
				//success
				setPopup(5);
			} else {
				//fail
				setPopup(3);
			}
		}
	};

	const recoveryPassword = async (event) => {
		event.preventDefault();
		if (
			isEmpty(event.target.passwordConfirm.value) ||
			isEmpty(event.target.password.value) ||
			isEmpty(event.target.code.value) ||
			passwordVerify !== password || letterNumber !== true || capital !== true || captchaValue === null
		) {
			document.querySelector("#code").parentElement.classList.add("error");
			document.querySelector("#password").parentElement.classList.add("error");
			document.querySelector("#passwordConfirm").parentElement.classList.add("error");
			return false;
		} else {
			let form = new FormData();
			form.append("c", event.target.code.value);
			form.append("n", event.target.password.value);
			form.append("nc", event.target.passwordConfirm.value);
			let result = await axios.post(
				`${process.env.REACT_APP_API_URL}recoveraction`, form
			);

			if (result.data.result === "ok") {
				setPassword(null);
				setPasswordVerify(null);
				setPopup(4);
			} else {
				setPopup(3);
			}
		}
	};

	////////////////
	//LOGIN METHOD//
	////////////////
	const onChangeValue = (e) => {
		setErrorEmailLogin(false);
		setErrorPasswordLogin(false);
		document.querySelector("#email").parentElement.classList.remove("error");
		document.querySelector("#password").parentElement.classList.remove("error");
		setState({
			...state,
			userInfo: {
				...state.userInfo,
				[e.target.name]: e.target.value,
			},
		});
	};
	// On Submit Login From
	const submitForm = async (event) => {
		event.preventDefault();

		if (isEmpty(state.userInfo.email)) {
			setErrorEmailLogin(true);
			document.querySelector("#email").parentElement.classList.remove("error");
			return false;
		} else if (isEmpty(state.userInfo.password)) {
			setErrorPasswordLogin(true);
			document.querySelector("#password").parentElement.classList.remove("error");
			return false;
		}

		const data = await loginUser(state.userInfo);
		if (data.result === "ok" && data.data.acckey) {
			setState({
				...initialState,
			});
			localStorage.setItem("loginToken", data.data.acckey);
			await isLoggedIn();
			if (!isEmpty(localStorage.getItem('loginRedirectUrl'))) {
				window.location.href = localStorage.getItem('loginRedirectUrl');
			}
			setRedirect(true);
		} else {
			setErrorPasswordLogin(false);
			setErrorEmailLogin(false);
			setState({
				...state,
				successMsg: "",
				errorMsg: data.msg,
			});
			if (data.msg === "no_account_found") {
				setErrorEmailLogin(true);
				document.querySelector("#email").parentElement.classList.add("error");
				setErrorPasswordLogin(true);
				document.querySelector("#password").parentElement.classList.add("error");
			}
		}
	};

	///////////////////
	//REGISTER METHOD//
	///////////////////
	const submitRegister = async (event) => {
		event.preventDefault();
		if (!isEmpty(username) && !isEmpty(email) && !isEmpty(password) && !isEmpty(passwordVerify) && !isEmpty(selectedLanguage) && !errorMail && !errorUserName && !errorPassword && !errorPasswordVerify && capital && letterNumber && captchaValue !== null) {
			let userInfo = {
				username,
				email,
				password,
				selectedLanguage
			};
			const data = await registerUser(userInfo);
			setErrorUserName(false);
			setErrorUserNameUsed(false);
			setErrorMailUsed(false);
			setErrorMail(false);
			if (data.result === "ok") {
				setPassword(null);
				setPasswordVerify(null);
				setPopup(1);
			} else {
				if (data.msg === "email_already_used") {
					setErrorMail(true);
					setErrorMailUsed(true);
					document.querySelector("#email").parentElement.classList.add("error");
				} else if (data.msg === "username_already_used") {
					setErrorUserName(true);
					setErrorUserNameUsed(true);
					document.querySelector("#username").parentElement.classList.add("error");
				}
			}
		} else {
			return false;
		}
	};

	const onChangeCaptcha = (value) => {

		setCaptchaValue(value);
	};

	const boxStyleHover = {
		backgroundColor: !captchaValue || !formCompleted ? "#F2F4F7" : (hover ? "#0B0D52" : "#3E6CAC"),
		color: !captchaValue || !formCompleted ? "#98A2B3" : "#fff"
	};

	return (
		<div className="account-page">
			{redirect && <Redirect to="/" />}
			{!availableMethods.includes(method) && <Redirect to="/" />}

			{popup && (
				<SuccessError
					content={popupContent}
					close={setPopup}
					recoveryPage={popup === 5}
					sendValidate={false}
				/>
			)}

			{method === undefined && (
				<section className="account">
					<div className="account-container">
						<h2 className="border untouch">
							{translations && !isEmpty(translations["accountWelcome"])
								? translations["accountWelcome"]
								: "accountWelcome"
							}
						</h2>
						{translations && !isEmpty(translations["accountText"])
							? translations["accountText"]
							: "accountText"
						}

						<NavLink to="/account/register">
							<article>
								<div className="svg">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
									</svg>
								</div>
								<div className="text">
									<h3>
										{translations && !isEmpty(translations["register"])
											? translations["register"]
											: "register"}
									</h3>
									{translations && !isEmpty(translations["registerText"])
										? translations["registerText"]
										: "registerText"
									}
								</div>
								<div className="arrow">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
									</svg>
								</div>
							</article>
						</NavLink>
						<NavLink to="/account/login">
							<article>
								<div className="svg">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
									</svg>
								</div>
								<div className="text">
									<h3>
										{translations && !isEmpty(translations["login"])
											? translations["login"]
											: "login"
										}
									</h3>
									{translations && !isEmpty(translations["loginText"])
										? translations["loginText"]
										: "loginText"
									}
								</div>
								<div className="arrow">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
									</svg>
								</div>
							</article>
						</NavLink>
					</div>
				</section>
			)}

			{(method === "login" ||
				method === "validate" ||
				method === "verification") && (
				<section className="account">
					<div className="account-container">
						<h2 className="untouch">
							{translations && !isEmpty(translations["button_login"])
								? translations["button_login"]
								: "Login"
							}
						</h2>
						<form onSubmit={submitForm} noValidate>
							<div className="input select">
								<input
									type="email"
									name="email"
									id="email"
									placeholder={
										translations && !isEmpty(translations["login_email_username_ph"])
											? translations["login_email_username_ph"]
											: "Username or email"
									}
									value={state.userInfo.email}
									required
									autoFocus
									autoComplete="email"
									onChange={onChangeValue}
								/>
								{errorEmailLogin && (
									<div className="errorDisplay">
										{translations && !isEmpty(translations["login_email_username_error"])
											? translations["login_email_username_error"]
											: "Error in username or email"}
									</div>
								)}
							</div>
							<div className="input">
								<input
									type="password"
									name="password"
									id="password"
									placeholder={
										translations && !isEmpty(translations["register_password_ph"])
											? translations["register_password_ph"]
											: "Password"
									}
									required
									autoComplete="current-password"
									value={state.userInfo.password}
									onChange={onChangeValue}
								/>
								{errorPasswordLogin && (
									<div className="errorDisplay">
										{translations && !isEmpty(translations["password_error"])
											? translations["password_error"]
											: "Password error"}
									</div>
								)}
							</div>
							<input
								style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
								type="submit"
								name="submit"
								id="submit"
								value={
									translations && !isEmpty(translations["button_login"])
										? translations["button_login"]
										: "Login"
								}
							/>
							<span className="reset_pass">
								{translations && !isEmpty(translations["forgot_your_password"])
									? translations["forgot_your_password"]
									: "Forgot your password"}{"? "}
								<NavLink to="/account/forgot">
									{translations && !isEmpty(translations["click_here"])
										? translations["click_here"]
										: "Click here"}
								</NavLink>
							</span>

							<span className="register">
								{translations && !isEmpty(translations["dont_have_account"])
									? translations["dont_have_account"]
									: "Don't have an account"}{"? "}
								<NavLink to="/account/register">
									{translations && !isEmpty(translations["button_register"])
										? translations["button_register"]
										: "Register"}
								</NavLink>
							</span>
						</form>
					</div>
				</section>
			)}
			{method === "forgot" && (
				<section className="account">
					<div className="account-container">
						<h2 className="border untouch">
							{translations && !isEmpty(translations["password_recovery"])
								? translations["password_recovery"]
								: "Password recovery"}
						</h2>
						<form onSubmit={sendRecovery} noValidate>
							<div className="input select">
								<input
									type="email"
									name="email"
									id="email"
									placeholder={
										translations && !isEmpty(translations["register_email_ph"])
											? translations["register_email_ph"]
											: "Email"
									}
									required
									autoFocus
									autoComplete="email"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div style={{display: captchaValue ? "none" : ""}}>
								<ReCAPTCHA
									sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
									onChange={onChangeCaptcha}
								/>
							</div>
							<input
								style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
								hidden={!captchaValue}
								type="submit"
								name="submit"
								id="submit"
								value={translations && !isEmpty(translations["recover"])
									? translations["recover"]
									: "Recover"
								}
							/>
						</form>

						<span className="reset_pass">
							{translations && !isEmpty(translations["enter_recovery_code"])
								? translations["enter_recovery_code"]
								: "Enter the code and a new password"}{" "}
							<NavLink to="/account/recovery">
								{translations && !isEmpty(translations["click_here"])
									? translations["click_here"]
									: "Click here"}
							</NavLink>
						</span>

					</div>
				</section>
			)}
			{method === "recovery" && (
				<section className="account">
					<div className="account-container">
						<h2 className="border untouch">
							{translations && !isEmpty(translations["passwordRecovery"])
								? translations["passwordRecovery"]
								: "Password recovery"}
						</h2>
						<form onSubmit={recoveryPassword} noValidate>
							<div className="input select">
								<input
									type="text"
									name="code"
									id="code"
									placeholder={
										translations && !isEmpty(translations["recovery_code"])
											? translations["recovery_code"]
											: "recovery_code"
									}
									required
									autoFocus
									autoComplete="recovery-code"
								/>
							</div>
							<div className="input select">
								<input
									type="password"
									name="password"
									id="password"
									placeholder={
										translations && !isEmpty(translations["new_register_password"])
											? translations["new_register_password"]
											: "new_register_password"
									}
									required
									autoFocus
									autoComplete="new-password"
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="input select">
								<input
									type="password"
									name="passwordConfirm"
									id="passwordConfirm"
									placeholder={
										translations && !isEmpty(translations["new_confirm_register_password"])
											? translations["new_confirm_register_password"]
											: "new_confirm_register_password"
									}
									required
									autoFocus
									autoComplete="new-password"
									onChange={(e) => setPasswordVerify(e.target.value)}
								/>
							</div>
							<div style={{display: captchaValue ? "none" : ""}}>
								<ReCAPTCHA
									sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
									onChange={onChangeCaptcha}
								/>
							</div>
							<input
								style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
								hidden={!captchaValue}
								type="submit"
								name="submit"
								id="submit"
								value={
									translations && !isEmpty(translations["recover"])
										? translations["recover"]
										: "Recover"
								}
							/>
						</form>
					</div>
				</section>
			)}
			{method === "register" && (
				<section className="account">
					<div className="account-container">
						<h2 className="border display">
							{translations && !isEmpty(translations["button_register"])
								? translations["button_register"]
								: "Register"}
						</h2>
						<form id="register-form" onSubmit={submitRegister} noValidate>
							<div className="page">
								<div className={errorMail ? "input select error": "input select"}>
									<input
										type="email"
										name="email"
										id="email"
										placeholder={
											translations && !isEmpty(translations["register_email_ph"])
												? translations["register_email_ph"]
												: "Email"
										}
										required
										autoFocus
										autoComplete="email"
										onChange={(e) => setEmail(e.target.value)}
									/>
									{errorMail && !errorMailUsed && (
										<div className="errorDisplay">
											{translations && !isEmpty(translations["register_email_error"])
												? translations["register_email_error"]
												: "Register email error"}
										</div>
									)}
									{errorMailUsed && (
										<div className="errorDisplay">
											{
												translations && !isEmpty(translations["register_email_used_error"])
													? translations["register_email_used_error"]
													: "The email is already in use"
											}
										</div>
									)}
								</div>
								<div className={errorUserName ? "input select error": "input select"}>
									<input
										type="text"
										name="username"
										id="username"
										placeholder={
											translations && !isEmpty(translations["register_username_ph"])
												? translations["register_username_ph"]
												: "User name"
										}
                                        value={state.userInfo.username}
                                        autoComplete="username"
										required
										onChange={(e) => setUsername(e.target.value)}
									/>
									{errorUserName && !errorUserNameUsed && (
										<div className="errorDisplay">
											{
												translations && !isEmpty(translations["register_username_error"])
												? translations["register_username_error"]
												: "Username registration error"
											}
										</div>
									)}
									{errorUserNameUsed && (
										<div className="errorDisplay">
											{
												translations && !isEmpty(translations["register_username_used_error"])
													? translations["register_username_used_error"]
													: "The username is already in use"
											}
										</div>
									)}
								</div>
								<div className={errorPassword ? "input select error": "input select"}>
									<input
										type= {showPassword ? "text" : "password"}
										name="password"
										id="password"
										placeholder={
											translations && !isEmpty(translations["register_password_ph"])
												? translations["register_password_ph"]
												: "Password"
										}
										required
										autoFocus
										autoComplete="new-password"
										onChange={(e) => setPassword(e.target.value)}
									/>
									<img  
										onClick={() => setShowPassword(!showPassword)}
										src={showPassword ? "/img/icons/hide.svg" : "/img/icons/show.svg"} 
											alt="Show/Hide Password" 
										/>
								</div>
								<div className={errorPasswordVerify ? "input select error": "input select"}>
									<input
										type= {showConfirmPassword ? "text" : "password"}
										name="passwordConfirm"
										id="passwordConfirm"
										placeholder={
											translations && !isEmpty(translations["register_password_confirm_ph"])
												? translations["register_password_confirm_ph"]
												: "Confirm password"
										}
										required
										autoFocus
										autoComplete="new-password"
										onChange={(e) => setPasswordVerify(e.target.value)}
									/>
									<img  
										onClick={() => setShowConfirmPassword(!showConfirmPassword)}
										src={showConfirmPassword ? "/img/icons/hide.svg" : "/img/icons/show.svg"} 
											alt="Show/Hide Password" 
										/>
								</div>

								<div className="error">
									<div className="validator">
										<div>
											{capital ? (
												<svg
													className="validate"
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
												</svg>
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
												</svg>
											)}
											{translations && !isEmpty(translations["register_password_capital"])
												? translations["register_password_capital"]
												: "One capital letter"
											}
										</div>
										<div>
											{letterNumber ? (
												<svg
													className="validate"
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
												</svg>
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
												</svg>
											)}
											{translations && !isEmpty(translations["register_password_characters"])
												? translations["register_password_characters"]
												: "8 characters minimum"
											}
										</div>
									</div>
								</div>
								
								<input
									style={boxStyleHover}
									onMouseEnter={() => setHover(!hover)}
									onMouseLeave={() => setHover(!hover)}
									form="register-form"
									type="submit"
									name="submit"
									id="submit"
									disabled={ !captchaValue }
									value={
										translations && !isEmpty(translations["button_register"])
											? translations["button_register"]
											: "Register"
								}
							/>

								<div style={{display: captchaValue ? "none" : ""}}>
									<ReCAPTCHA
										sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
										onChange={onChangeCaptcha}
									/>
								</div>

							</div>

							<span className="reset_pass">
								{translations && !isEmpty(translations["already_have_account"])
									? translations["already_have_account"] + " "
									: "Already have an account? "}
								<NavLink to="/account/login">
									{translations && !isEmpty(translations["button_login"])
										? translations["button_login"]
										: "Login"}
								</NavLink>
							</span>
						</form>
					</div>
				</section>
			)}
			<div className="banner">
				{method === "register" ?
					(<img src="/img/banner-register.png" alt="banner account" />) :
					(<img src="/img/banner-account.png" alt="banner account" />)
				}
			</div>
		</div>
	);
};

export default Account;
