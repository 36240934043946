import React, {useEffect, useState} from "react";
import { isEmpty } from "../Utils";
import {useSelector} from "react-redux";
import LeftArrow from "../slider/LeftArrow";
import RightArrow from "../slider/RightArrow";
import Slider from "react-slick/lib";
import SliderStyle from "../SliderStyle";

const Banner = () => {

	const slidesAll = useSelector((state) => state.slideShowReducer.slideShowReducer.slides);
	const slideLive = useSelector((state) => state.slideShowReducer.slideShowReducerLive.slides);
	const [slides, setSlides] = useState([]);

	useEffect(() => {
		if (!isEmpty(slideLive)) {
			setSlides(slideLive);
		} else {
			setSlides(slidesAll);
		}
	}, [slideLive, slidesAll]);

	//SLIDE
	let settings = {
		dots: true,
		infinite: true,
		fade: true,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />
	};

	return (
		<div className="banner direct" id="scrollButton">
			<div className="slides-container">
				{!isEmpty(slides) && (
					<Slider {...settings}>
						{!isEmpty(slides) &&
						slides.map((slide, index) => {
							return (
								<SliderStyle slide={slide} styleProp="2" key={index}/>
							);
						})}
					</Slider>
				)}
			</div>
		</div>
	);
};

export default Banner;
