import React, { useEffect, useRef, useState } from "react";
import {NavLink} from "react-router-dom";
import {isEmpty} from "./Utils";
import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import axios from "axios";
import moment from "moment";

const PlaylistEvents = (props) => {
	const {events, title, type, nextPage, showCount} = props;

	const sliderRef = useRef(null);
	const slider = useRef();
	const [width, setWidth] = useState();

	const [eventsArray, setEventsArray] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(5);
	const [hasNexPage, setHasNextPage] = useState(nextPage);
	const [countPage, setCountPage] = useState(1);

	const getWidth = () => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			const newWidth = sliderRef.current.clientWidth;
			setWidth(newWidth);
		}
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 150);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		if (sliderRef.current && sliderRef.current.clientWidth) {
			getWidth();
		}
	}, []);

	useEffect(() => {
		window.addEventListener("resize", getWidth);
		return () => {
			window.removeEventListener("resize", getWidth);
		};
	}, []);

	let settings = {
		infinite: false,
		//lazyLoad: true,
		speed: 500,
		slidesToShow: Math.ceil((isEmpty(width) ? 700 : width) / 300),
		slidesToScroll: itemsPerPage,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		draggable: true,
		initialSlide: 0,
		centerMode: false,
		afterChange: (index) => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				nextPageHandler();
			}
		}
	};

	useEffect(() => {
		setEventsArray(events)
	}, [events]);

	useEffect(() => {
		if (!isEmpty(width)) {
			setItemsPerPage(Math.ceil(width / 300));
		}
		else
		{
			setItemsPerPage(5);
		}
	}, [width]);

	const nextPageHandler = () => {
		if (hasNexPage !== null) {
			axios
				.get(`${process.env.REACT_APP_API_URL}getallLives&p=${countPage}&n=10&t=${type}`)
				.then((res) => {
					console.log("get next (" + countPage + ") page for events");
					if (type === 1) {
						setHasNextPage(res.data.live.nextpage);
						setEventsArray([...eventsArray, ...res.data.live.events]);
					} else {
						setHasNextPage(res.data.upcoming.nextpage);
						setEventsArray([...eventsArray, ...res.data.upcoming.events]);
					}
					setCountPage(countPage + 1);
				})
				.catch((error) => {
					console.log(error)
				});
		}

	};

	return (
		!isEmpty(eventsArray) ? (
			<div className="playlist-videos filter white events">
				<div className="titles">
					<section
						className="flex-between m-auto"
					>
						<h3>{!isEmpty(title) && title}
						{!isEmpty(showCount) && (
							<span className="count">{showCount}</span>
						)}
						</h3>
					</section>
				</div>
				<div ref={sliderRef}>
					<Slider ref={slider} {...settings} className="videos-container hide-scroll customScroll">
						{eventsArray.map((event) => {
							return (
								<div className="video" key={event.id}>
									<NavLink
										exact
										draggable="false"
										rel="noopener noreferrer"
										to={`/live/${event.id}`}
										onClick={() => window.scrollTo(0, 0)}
									>
										<img
											className="image event"
											src={event.pic}
											alt={`Apercu de ${event.pic}`}
											width="100"
											height="66"
										/>
										{!isEmpty(event.payable) && event.payable && (
											<svg className="payable" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.6 257.6"><circle cx="128.8" cy="128.8" r="126.6" fill="#fff"/><path d="M1459.1,658.9a128.9,128.9,0,0,1-128.7-125.8,128.7,128.7,0,1,1,131.7,125.8Zm.1-253.2h-3A124.3,124.3,0,0,0,1334.8,533c1.5,68.5,58.7,123.1,127.2,121.4a124.4,124.4,0,0,0-2.8-248.7Z" transform="translate(-1330.3 -401.3)"/><circle cx="1459.1" cy="530.1" r="114.4" transform="translate(-1277.8 785.7) rotate(-45)" fill="#fff"/><circle cx="1459.1" cy="530.1" r="101.2" transform="translate(-1277.8 785.7) rotate(-45)" fill="#fff"/><path d="M1459,636.7a106.7,106.7,0,0,1-106.5-104.1,106.6,106.6,0,0,1,193.6-64.1,2.1,2.1,0,0,1-.5,3,2.2,2.2,0,0,1-3.1-.5,102.2,102.2,0,0,0-85.8-43.1c-56.3,1.3-101,48.2-99.7,104.6a102.2,102.2,0,1,0,204.3-4.8,2.1,2.1,0,0,1,2.1-2.2,2.2,2.2,0,0,1,2.3,2.1,106.9,106.9,0,0,1-104.1,109.1Z" transform="translate(-1330.3 -401.3)"/><path d="M1452.7,590.5l-.3-14c-10-.2-19.6-2.6-25.3-5.8l4-17.3a53.3,53.3,0,0,0,24.7,5.9c8.4-.2,14.1-3.6,14-9.5s-5-9-16-12.5c-15.9-4.9-26.9-12.1-27.2-26.4s8.6-23.4,24.4-26.9l-.4-14,14.5-.4.3,13c9.9.3,16.6,2.2,21.5,4.4l-3.9,16.8c-3.8-1.5-10.7-4.8-21.3-4.5s-12.7,4.4-12.6,8.6,5.4,7.8,18.1,12.3c17.7,5.7,25,13.7,25.3,27s-8.8,24.6-25.7,27.9l.4,15.1Z" transform="translate(-1330.3 -401.3)"/></svg>
										)}
										<div className="title-container">
											<div className="play">
												<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
													 fill="none" xmlns="http://www.w3.org/2000/svg">
													<path className="round"
														  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
														  fill="#362CAE"/>
													<path className="play_sign"
														  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
														  fill="white"/>
												</svg>
											</div>
											<div className="video-date">
												<div className="line">
													<svg className="bar" width="100" height="4" viewBox="0 0 100 4" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect width="100" height="4" rx="2" fill="url(#paint0_radial_1607_3199)"/>
														<defs>
															<radialGradient id="paint0_radial_1607_3199" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 2) rotate(90) scale(2 27)">
																<stop stopColor="#4E45AF"/>
																<stop offset="1" stopColor="#362CAE"/>
															</radialGradient>
														</defs>
													</svg>
												</div>
												<div className="date">
													{event.start && moment(event.start).format("DD/MM HH:mm")}
												</div>
											</div>
											<h4>{event.title}</h4>
											<div className="video-description">
												{!isEmpty(event.description) && event.description.length > 180 ? event.description.slice(0,180) + "..." : event.description}
											</div>
										</div>
									</NavLink>
								</div>
							);
						})}
					</Slider>
				</div>
			</div>
		) : (
			<div/>
		)
	);
};

export default PlaylistEvents;
