import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../contexts/MyContext";
import Avatar from "./Avatar";
import { isEmpty } from "../Utils";
import { useSelector } from "react-redux";
import Delete from "./Delete";
import {useMediaQuery} from "react-responsive";

const AccountParam = () => {
	const { rootState, updateUserData, isLoggedIn } = useContext(MyContext);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const selectedLanguage = translationsReducer.language;
	const translations = translationsReducer.translate;
	const { theUser } = rootState;
	const [name, setName] = useState(null);
	const [username, setUsername] = useState(null);
	const [errorName, setErrorName] = useState(false);
	const [errorUsername, setErrorUsername] = useState(false);
	const [allow, setAllow] = useState(false);
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

	useEffect(() => {
		if (!isEmpty(theUser)) {
			setName(theUser.name);
			setUsername(theUser.username);
		}
	}, [theUser]);

	//update data user
	const submitForm = async (event) => {
		event.preventDefault();
		if (allow) {
			let userInfo = {
				name,
				username,
				lang: selectedLanguage
			};
			const data = await updateUserData(userInfo);
			setErrorUsername(false);
			setErrorName(false);
			if (data.result === "ok") {
				if (document.querySelector(".account > .grid #validUpdate")) {
					setAllow(false);

					setTimeout(() => {
						document
							.querySelector(".account > .grid #validUpdate")
							.classList.add("display");
					}, 10);

					setTimeout(() => {
						if (document.querySelector(".account > .grid #validUpdate")) {
							document
								.querySelector(".account > .grid #validUpdate")
								.classList.remove("display");
						}
					}, 3000);
				}
				await isLoggedIn();
			} else {

				setErrorUsername(true);
				setErrorName(true);
				document.querySelector("#username").parentElement.classList.add("error");
				document.querySelector("#firstName").parentElement.classList.add("error");

				console.log("erreur : " + data.msg);
			}
		}
	};

	//HANDLE ERROR
	useEffect(() => {
		if (!isEmpty(name)) {
			name.length < 2 || !name.match(/^[a-zA-ZÀ-ÿ\s-]+$/)
				? document.querySelector("#firstName").parentElement.classList.add("error")
				: document
						.querySelector("#firstName")
						.parentElement.classList.remove("error");
		}

		if (!isEmpty(username)) {
			username.length < 2 || !username.match(/^[a-zA-Z0-9_]*$/)
				? document.querySelector("#username").parentElement.classList.add("error")
				: document
						.querySelector("#username")
						.parentElement.classList.remove("error");
		}

	}, [username, name]);

	const submitHandle = () => {
		isEmpty(name) &&
			document.querySelector("#firstName").parentElement.classList.add("error");
		isEmpty(username) &&
			document.querySelector("#username").parentElement.classList.add("error");
		if (document.querySelectorAll(".input.error").length > 0) {
			setAllow(false);
			document.querySelectorAll(".input.error").forEach((elm) => {
				elm.classList.remove("error");
				setTimeout(() => {
					elm.classList.add("error");
				}, 100);
			});
		} else {
			setAllow(true);
		}
	};

	return (
		<section className="account block">
			<div className="title">
				<h3>
					{translations && !isEmpty(translations["account"])
						? translations["account"]
						: "account"
					}
				</h3>
			</div>
			<div className="grid">
				<article>
					<form onSubmit={submitForm} noValidate>
						<div className="input firstName">
							<label>
								{translations && !isEmpty(translations["register_name_ph"])
									? translations["register_name_ph"]
									: "register_name_ph"}{" "}
								*
							</label>
							<input
								type="text"
								name="firstName"
								id="firstName"
								placeholder={
									translations && !isEmpty(translations["register_name_ph"])
										? translations["register_name_ph"]
										: "Name"
								}
								value={!isEmpty(name) ? name : ''}
								required
								autoFocus
								autoComplete="given-name"
								onChange={(e) => setName(e.target.value)}
							/>
							{errorName && (
								<div className="errorDisplay">
									{translations && !isEmpty(translations["register_name_error"])
										? translations["register_name_error"]
										: "Name error"}
								</div>
							)}
						</div>
						<div className="input username">
							<label>
								{translations && !isEmpty(translations["register_username_ph"])
									? translations["register_username_ph"]
									: "register_username_ph"}{" "}
								*
							</label>
							<input
								type="text"
								name="username"
								id="username"
								placeholder={
									translations
										? translations["register_username_ph"]
										: "User name"
								}
								value={username ? username : ''}
								autoComplete="username"
								required
								onChange={(e) => setUsername(e.target.value)}
							/>
							{errorUsername && (
								<div className="errorDisplay">
									{translations && !isEmpty(translations["register_username_error"])
										? translations["register_username_error"]
										: "Username registration error"}
								</div>
							)}
						</div>

						<div className="submit">
							<input
								style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
								type="submit"
								value={
									translations && !isEmpty(translations["button_update"])
										? translations["button_update"]
										: "Update"
								}
								onClick={submitHandle}
							/>
						</div>
						<div id="validUpdate">
							{translations && !isEmpty(translations["text_updated"])
								? translations["text_updated"]
								: "The record has been updated"
							}
						</div>
					</form>
				</article>
				<Avatar />
				{!isMobile && <Delete />}

			</div>

		</section>
	);
};

export default AccountParam;
