import React from "react";
import {useSelector} from "react-redux";

const ToastModal = () => {
    const modalReduces = useSelector(
        (state) => state.modalReducer.toggleReducer
    );

    return (
        <div className={`toast-modal ${modalReduces.opened ? "display" : ""} ${modalReduces.success ? "success" : "error"}`}>
            <div className="title">
                <p>{modalReduces.text && modalReduces.text}</p>
            </div>
        </div>
    );
};

export default ToastModal;