import axios from "axios";
import {isEmpty} from "../components/Utils";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_LIST_PAYMENTS = "GET_LIST_PAYMENTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_INVOICES = "GET_INVOICES";
export const GET_PAYMENTS_INTENTS = "GET_PAYMENTS_INTENTS";
export const GET_ACTIVE_CUSTOMER_PRODUCT = "GET_ACTIVE_CUSTOMER_PRODUCT";
const Axios = axios.create();

export const getCustomer = () => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getStripeCustomer`, formData)
					.then((res) => {
						if (res.data.result === "ok") {
							dispatch({type: GET_CUSTOMER, payload: res.data.response})
						} else {
							dispatch({type: GET_CUSTOMER, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	};
};

export const getProduct = (productToken, includePrices) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("source", productToken);
				if (includePrices) {
					formData.append("include_prices", includePrices);
				}
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getProduct`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_PRODUCT, payload: res.data.response})
						} else {
							dispatch({type: GET_PRODUCT, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getActiveCustomerProduct = () => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getActiveCustomerProduct`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_ACTIVE_CUSTOMER_PRODUCT, payload: res.data.response})
						} else {
							dispatch({type: GET_ACTIVE_CUSTOMER_PRODUCT, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getProducts = (includePrices) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				if (includePrices) {
					formData.append("include_prices", includePrices);
				}
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getProducts`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_PRODUCTS, payload: res.data.response})
						} else {
							dispatch({type: GET_PRODUCTS, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getInvoices = (clientSecret, page) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("page", page);
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getInvoices`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response.data)) {
							dispatch({type: GET_INVOICES, payload: res.data.response.data})
						} else {
							dispatch({type: GET_INVOICES, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getPaymentsIntents = (clientSecret, page) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("page", page);

				await Axios.post(`${process.env.REACT_APP_API_STRIPE}paymentIntents`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response.data)) {
							dispatch({type: GET_PAYMENTS_INTENTS, payload: res.data.response.data})
						} else {
							dispatch({type: GET_PAYMENTS_INTENTS, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getListPaymentsMethods = (clientSecret) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getListPaymentsMethods`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response.data)) {
							dispatch({type: GET_LIST_PAYMENTS, payload: res.data.response.data})
						} else {
							dispatch({type: GET_LIST_PAYMENTS, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};