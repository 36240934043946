import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {getDisciplines} from "../actions/disciplines.action";
import {isEmpty} from "../components/Utils";
import {useMediaQuery} from "react-responsive";
import {NavLink} from "react-router-dom";
import {getVideosFrom} from "../actions/videos.action";
import Filter from "../components/discipline/Filter";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import {Helmet} from "react-helmet-async";
import moment from "moment";

const DisciplinePage = () => {

	const { discipline } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const disciplinesReducer = useSelector((state) => state.disciplineReducer.getDisciplinesReducer);
	const videosReducer = useSelector((state) => state.videosReducer.getVideosFromReducer);
	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const [devicesSelect, setDevicesSelect] = useState("");
	const [regionSelect, setRegionSelect] = useState("");
	const [scrollPage, setScrollPage] = useState(0);
	const [videos, setVideos] = useState(null);
	const [hasNextPage, setHasNextPage] = useState(true);
	const translations = translationsReducer.translate;
	const [selectDiscipline, setSelectDiscipline] = useState({
		id: "",
		name: "",
		name_web: "",
		pic: ""
	});

	useEffect(() => {
		dispatch(getDisciplines(20));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(selectDiscipline.id)) {
			dispatch(getVideosFrom("discipline", selectDiscipline.id));
		}
		// eslint-disable-next-line
	}, [selectDiscipline]);


	useEffect(() => {
		if (!isEmpty(videosReducer)) {
			setVideos(videosReducer.videos)
		}
	}, [videosReducer]);

	useEffect(() => {
		if (!isEmpty(disciplinesReducer.disciplines)) {
			let select = disciplinesReducer.disciplines.filter((dis) => {
				return discipline === dis.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
			});
			setSelectDiscipline(...select);
		}
	}, [disciplinesReducer, discipline]);

	const onSelectFilter = (devicesSelect, regionSelect) => {
		setHasNextPage(true);
		setScrollPage(0);
		dispatch(getVideosFrom("discipline", selectDiscipline.id, 0, devicesSelect, regionSelect));
		setDevicesSelect(devicesSelect);
		setRegionSelect(regionSelect);
	};

	const fetchVideos = async () => {
		let incrementPage = scrollPage + 1;
		setScrollPage(incrementPage);
		await axios
			.get(`${process.env.REACT_APP_API_URL}getvideosfrom&f=discipline&i=${selectDiscipline.id}&di=0&de=${devicesSelect}&re=${regionSelect}&p=${incrementPage}&n=20`)
			.then((res) => {
				let responseVideos = res.data.videos;
				setVideos([...videos, ...responseVideos]);
				setHasNextPage(res.data.nextpage !== null);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/discipline`);
		}
	};

	return (
		<>
			{!isEmpty(selectDiscipline.name) && (
				<Helmet>
					<meta name="title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${selectDiscipline.name}`} />
					<meta property="og:title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${selectDiscipline.name}`} />
					<meta property="og:description" content={`${process.env.REACT_APP_CLIENT_NAME} - ${selectDiscipline.name}`} />
					{!isEmpty(selectDiscipline.pic) && (
						<meta property="og:image" content={`${selectDiscipline.pic}`} />
					)}
				</Helmet>
			)}


			<main className="discipline-page">
				<div className="discipline-title">
					<div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
						<h1>
							<span onClick={() => clickBack()}/>
							{isMobile ? selectDiscipline.name : selectDiscipline.name_web}
						</h1>
					</div>
					<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="20%" height="2" fill="#1B3FFF"/>
						<rect x="20%" width="20%" height="2" fill="#D43D17"/>
						<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
						<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
						<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
					</svg>
				</div>


				<Filter
					onSelectFilter={onSelectFilter}
					disciplineId={selectDiscipline.id}
				/>

				<div className="content">
					{!isEmpty(videos) &&
					<InfiniteScroll
						dataLength={videos.length}
						next={fetchVideos}
						hasMore={hasNextPage}
						style={{ overflow: 'hidden' }}
					>
						{videos.map((video) => {
							return (
								<div className="video" key={video.id}>
									<NavLink
										exact
										rel="noopener noreferrer"
										to={`/video/${video.id}`}
										onClick={() => window.scrollTo(0, 0)}
									>
										<img
											className="image"
											src={video.image_desktop}
											alt={`Apercu de ${video.name}`}
											width="100"
											height="66"
										/>
										{!isEmpty(video.payable) && video.payable && (
											<svg className="payable" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.6 257.6"><path fill="#fff" d="M1452.7,590.5l-.3-14c-10-.2-19.6-2.6-25.3-5.8l4-17.3a53.3,53.3,0,0,0,24.7,5.9c8.4-.2,14.1-3.6,14-9.5s-5-9-16-12.5c-15.9-4.9-26.9-12.1-27.2-26.4s8.6-23.4,24.4-26.9l-.4-14,14.5-.4.3,13c9.9.3,16.6,2.2,21.5,4.4l-3.9,16.8c-3.8-1.5-10.7-4.8-21.3-4.5s-12.7,4.4-12.6,8.6,5.4,7.8,18.1,12.3c17.7,5.7,25,13.7,25.3,27s-8.8,24.6-25.7,27.9l.4,15.1Z" transform="translate(-1330.3 -401.3)"/></svg>
										)}
										<div className="title-container">
											<div className="play">
												<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
													 fill="none" xmlns="http://www.w3.org/2000/svg">
													<path className="round"
														  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
														  fill="#362CAE"/>
													<path className="play_sign"
														  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
														  fill="white"/>
												</svg>
											</div>
											<div className="video-date">
												<div className="line">
													<svg className="bar" width="100" height="4" viewBox="0 0 100 4" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect width="100" height="4" rx="2" fill="url(#paint0_radial_1607_3199)"/>
														<defs>
															<radialGradient id="paint0_radial_1607_3199" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 2) rotate(90) scale(2 27)">
																<stop stopColor="#4E45AF"/>
																<stop offset="1" stopColor="#362CAE"/>
															</radialGradient>
														</defs>
													</svg>
												</div>
												<div className="date">
													{video.addedon && moment(video.addedon).format("DD/MM/YYYY")}
												</div>
											</div>
											<h4>{video.name}</h4>
											<div className="video-description">
												{!isEmpty(video.descr) && video.descr.length > 180 ? video.descr.slice(0,180) + "..." : video.descr}
											</div>
										</div>
									</NavLink>
								</div>
							)
						})}
					</InfiniteScroll>
					}
				</div>
			</main>
		</>
	);
};

export default DisciplinePage;
