import { combineReducers } from "redux";
import eventsReducer from "./eventsReducer";
import playlistReducer from "./playlistReducer";
import searchReducer from "./searchReducer";
import langReducer from "./langReducer";
import slideShowReducer from "./slideShowReducer";
import accountReducer from "./accountReducer";
import notificationsReducer from "./notificationsReducer";
import programReducer from "./programReducer";
import disciplineReducer from "./disciplineReducer";
import videosReducer from "./videosReducer";
import filterReducer from "./filterReducer";
import settingReducer from "./settingReducer";
import infoReducer from "./infoReducer";
import modalReducer from "./modalReducer";
import stripeReducer from "./stripeReducer";

//COMBINE ALL REDUCERS
export default combineReducers({
	accountReducer,
	eventsReducer,
	searchReducer,
	playlistReducer,
	langReducer,
	settingReducer,
	programReducer,
	disciplineReducer,
	videosReducer,
	slideShowReducer,
	notificationsReducer,
	filterReducer,
	infoReducer,
	modalReducer,
	stripeReducer
});
