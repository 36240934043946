import React, {Fragment, useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { MyContext } from "../../contexts/MyContext";
import {isEmpty} from "../Utils";

const SecurityParam = () => {
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);

	const [oldPassword, setOldPassword] = useState(null);
	const [password, setPassword] = useState(null);
	const [passwordVerify, setPasswordVerify] = useState(null);
	const [capital, setCapital] = useState(false);
	const [letterNumber, setLetterNumber] = useState(false);
	const [stateEdit, setStateEdit] = useState(false);
	const [allow, setAllow] = useState(false);
	const { rootState } = useContext(MyContext);
	const { theUser, logoutUser } = rootState;

	useEffect(() => {
		if (!isEmpty(oldPassword)) {
			if (oldPassword.length < 8 || !oldPassword.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)) {
				document.querySelector("#oldPassword").parentElement.classList.add("error")
			} else {
				document
					.querySelector("#oldPassword")
					.parentElement.classList.remove("error");
			}
		}
		if (!isEmpty(password)) {
			password.length >= 8 ? setLetterNumber(true) : setLetterNumber(false);
			password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)
				? setCapital(true)
				: setCapital(false);

			password.length < 8 ||
			!password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)
				? document.querySelector("#password").parentElement.classList.add("error")
				: document
						.querySelector("#password")
						.parentElement.classList.remove("error");
		}
		if (!isEmpty(password) && !isEmpty(passwordVerify)) {
			passwordVerify !== password
				? document
						.querySelector("#passwordConfirm")
						.parentElement.classList.add("error")
				: document
						.querySelector("#passwordConfirm")
						.parentElement.classList.remove("error");
		}
	}, [oldPassword, password, passwordVerify]);

	useEffect(() => {
		setAllow(false);
		if (
			!isEmpty(password) &&
			!isEmpty(passwordVerify) &&
			capital &&
			letterNumber &&
			passwordVerify === password
		) {
			setAllow(true);
		}
	}, [capital, letterNumber, password, passwordVerify]);

	const { updatePassword } = useContext(MyContext);

	const submitForm = async (event) => {
		event.preventDefault();
		if (allow) {
			const data = await updatePassword(oldPassword, password, passwordVerify);
			if (data.result === "ok") {
				logoutUser();
				window.location.href = "/";
			} else {
				console.log(data.message);
			}
		}
	};
	return (
		<section className="security block">
			<div className="title">
				<h3>
					{translations && !isEmpty(translations["menu_settings"])
						? translations["menu_settings"]
						: "Settings"
					}
				</h3>
				<span className="edit" onClick={() => setStateEdit(!stateEdit)} style={{background: !isEmpty(settings) ? settings.hover_color : ""}}>
					{translations && !isEmpty(translations["button_edit"])
						? translations["button_edit"]
						: "Edit"
					}</span>
			</div>
			<article>
				{stateEdit ? (
					<form onSubmit={submitForm}>
						<div className="input select">
							<label>
								{translations && !isEmpty(translations["current_password"])
									? translations["current_password"]
									: "current_password"}{" "}
								*
							</label>
							<input
								type="password"
								name="oldPassword"
								id="oldPassword"
								placeholder={
									translations && !isEmpty(translations["current_password"])
										? translations["current_password"]
										: "Current password"
								}
								required
								autoFocus
								autoComplete="new-password"
								onChange={(e) => setOldPassword(e.target.value)}
							/>
						</div>
						<div className="input select">
							<label>
								{translations && !isEmpty(translations["register_password_ph"])
									? translations["register_password_ph"]
									: "Password"}{" "}
								*
							</label>
							<input
								type="password"
								name="password"
								id="password"
								placeholder={
									translations && !isEmpty(translations["register_password_ph"])
										? translations["register_password_ph"]
										: "Password"
								}
								required
								autoFocus
								autoComplete="new-password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="input select">
							<label>
								{translations && !isEmpty(translations["register_password_confirm_ph"])
									? translations["register_password_confirm_ph"]
									: "Confirm password"}{" "}
								*
							</label>
							<input
								type="password"
								name="passwordConfirm"
								id="passwordConfirm"
								placeholder={
									translations && !isEmpty(translations["register_password_confirm_ph"])
										? translations["register_password_confirm_ph"]
										: "Confirm password"
								}
								required
								autoFocus
								autoComplete="new-password"
								onChange={(e) => setPasswordVerify(e.target.value)}
							/>
						</div>
						<div className="error">
							<div className="validator">
								<div>
									{capital ? (
										<svg
											className="validate"
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
										</svg>
									) : (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
										</svg>
									)}
									{translations && !isEmpty(translations["register_password_capital"])
										? translations["register_password_capital"]
										: "One capital letter"
									}
								</div>
								<div>
									{letterNumber ? (
										<svg
											className="validate"
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
										</svg>
									) : (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
										</svg>
									)}
									{translations && !isEmpty(translations["register_password_characters"])
										? translations["register_password_characters"]
										: "8 characters minimum"
									}
								</div>
							</div>
						</div>
						<input style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
							type="submit"
							value={
								translations && !isEmpty(translations["button_update"])
									? translations["button_update"]
									: "Update"
							}
						/>
					</form>
				) : (
					<Fragment>
						<div className="grid">
							<p>
								{translations && !isEmpty(translations["text_password_last_updated"])
									? translations["text_password_last_updated"]
									: "Last updated on"
								} {": "} <span style={{color: "red"}}>{isEmpty(theUser.passwordupdate) ? "newer" : theUser.passwordupdate}</span>
							</p>
							<p>
								{translations && !isEmpty(translations["text_password_update_recommendation"])
									? translations["text_password_update_recommendation"]
									: "We recommend that you update your password regularly to prevent unauthorized access."
								}
							</p>
						</div>
					</Fragment>
				)}
			</article>
		</section>
	);
};

export default SecurityParam;
