import React, {useContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../components/explorer/Banner";
import Events from "../components/Events";
import PlaylistVideos from "../components/PlaylistVideos";
import { isEmpty } from "../components/Utils";
import { getEventsLive } from "../actions/events.action";
import { getPrograms } from "../actions/programs.action";
import {getPlaylists, getPopularVideoPlaylist, getPrivatePlaylists} from "../actions/playlist.action";
import Programmes from "../components/Programmes";
import PlaylistPopularVideos from "../components/PlaylistPopularVideos";
import PlaylistEvents from "../components/PlaylistEvents";
import {MyContext} from "../contexts/MyContext";
import {getVideoFavorite} from "../actions/videos.action";
import PlaylistFavoriteVideos from "../components/PlaylistFavoriteVideos";
import PrivatePlaylistVideos from "../components/PrivatePlaylistVideos";
import {useMediaQuery} from "react-responsive";

const Explorer = () => {
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const dispatch = useDispatch();
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;

	const eventsReducer = useSelector(
		(state) => state.eventsReducer.eventsAllReducer
	);
	const programReducer = useSelector(
		(state) => state.programReducer.getProgramsReducer
	);

	const mainPlaylistsReducer = useSelector(
		(state) => state.playlistReducer
	);
	const playlistsReducer = mainPlaylistsReducer.playlistsReducer;
	const playlistsPopularVideoReducer = mainPlaylistsReducer.playlistsPopularVideoReducer;
	const privatePlaylistsReducer = mainPlaylistsReducer.privatePlaylistsReducer;

	const videosFavoritesReducer = useSelector(
		(state) => state.videosReducer.getVideoFavoriteReducer
	);
	const [events, setEvents] = useState([]);
	const [upcoming, setUpcoming] = useState([]);
	const [programmes, setProgrammes] = useState([]);
	const [playlists, setPlaylists] = useState([]);
	const [popularVideos, setPopularVideos] = useState([]);
	const [favoriteVideos, setFavoriteVideos] = useState([]);

	useEffect(() => {
		if (isAuth) {
			dispatch(getVideoFavorite("0", "3", "10"));
			dispatch(getPrivatePlaylists(0, 5));
		}
	},[dispatch, isAuth]);

	useEffect(() => {
		if (!isEmpty(videosFavoritesReducer.videos)) {
			setFavoriteVideos(videosFavoritesReducer.videos);
		}
	}, [videosFavoritesReducer]);

	useEffect(() => {
		dispatch(getEventsLive(10, 0, 0));
		dispatch(getPrograms());
		dispatch(getPlaylists(0, 5));
		dispatch(getPopularVideoPlaylist(0, 10));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(eventsReducer.live)) {
			setEvents(eventsReducer.live.events)
		}
		if (!isEmpty(eventsReducer.upcoming)) {
			setUpcoming(eventsReducer.upcoming.events);
		}
	}, [eventsReducer]);

	useEffect(() => {
		if (!isEmpty(programReducer.programs)) {
			setProgrammes(programReducer.programs)
		}
	}, [programReducer]);

	useEffect(() => {
		if (!isEmpty(playlistsReducer.playlists)) {
			setPlaylists(playlistsReducer.playlists)
		}
	}, [playlistsReducer]);

	useEffect(() => {
		if (!isEmpty(playlistsPopularVideoReducer.videos)) {
			setPopularVideos(playlistsPopularVideoReducer.videos)
		}
	}, [playlistsPopularVideoReducer]);

	return (
		<main className="explorer">
			<Banner />
			{!isEmpty(events) &&
				<Events
					title={
						translations && !isEmpty(translations["page_live_events"])
							? translations["page_live_events"]
							: "Events"
					}
					events={events}
					nextPage={eventsReducer.live.nextpage}
					total={eventsReducer.live.total}
					showMore={true}
			/>}

			{!isEmpty(favoriteVideos) &&
				<PlaylistFavoriteVideos
					name={translations && !isEmpty(translations["my_videos"])
						? translations["my_videos"]
						: "My Videos"
					}
					videos={favoriteVideos}
					nextPage={!isEmpty(videosFavoritesReducer.videos) ? videosFavoritesReducer.nextpage : false}
				/>
			}

			{!isEmpty(privatePlaylistsReducer?.playlists) && isAuth &&
			privatePlaylistsReducer.playlists
				.map((playlists, index) => {
					return (
						<PrivatePlaylistVideos
							key={index}
							id={playlists["id"]}
							name={isMobile ? playlists["name"] : playlists["name_web"]}
							videos={playlists["vids"]["videos"]}
							nextPage={playlists["vids"]["nextpage"]}
						/>
					);
				})
			}

			{!isEmpty(popularVideos) &&
				<PlaylistPopularVideos
					name={translations && !isEmpty(translations["most_viewed_videos"])
						? translations["most_viewed_videos"]
						: "Most viewed videos"
					}
					videos={popularVideos}
				/>
			}

			{!isEmpty(upcoming) && (
				<PlaylistEvents
					events={upcoming}
					title={translations && !isEmpty(translations["next_directs"])
						? translations["next_directs"]
						: "Next directs"
					}
					type={2}
					nextPage={eventsReducer.upcoming ? eventsReducer.upcoming.nextpage : false}
				/>
			)}

			{!isEmpty(programmes) && <Programmes programmes={programmes} />}

			{!isEmpty(playlists) &&
				Object.keys(playlists)
					.map((key, index) => {
						return (
							<PlaylistVideos
								key={index}
								id={playlists[key]["id"]}
								name={isMobile ? playlists[key]["name"] : playlists[key]["name_web"]}
								videos={playlists[key]["vids"]["videos"]}
								nextPage={playlists[key]["vids"]["nextpage"]}
							/>
						);
					})
			}

		</main>
	);
};

export default Explorer;
