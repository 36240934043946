import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {isEmpty} from "../components/Utils";
import { NavLink, useHistory } from "react-router-dom";
import {getVideosFrom} from "../actions/videos.action";
import Filter from "../components/videos/Filter";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from 'axios';
import moment from "moment";

const Videos = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const videosReducer = useSelector((state) => state.videosReducer.getVideosFromReducer);
	const [videos, setVideos] = useState(null);
	const [disciplineSelect, setDisciplineSelect] = useState("");
	const [devicesSelect, setDevicesSelect] = useState("");
	const [regionSelect, setRegionSelect] = useState("");
	const [scrollPage, setScrollPage] = useState(0);
	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const translations = translationsReducer.translate;

	useEffect(() => {
		dispatch(getVideosFrom("all", "", "", "", "", 0, 20));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(videosReducer)) {
			setVideos(videosReducer.videos)
		}
	}, [videosReducer]);

	const clickBack = () => {
		history.push(`/`);
	};

	const onSelectFilter = (discipline, device, region) => {
		setDisciplineSelect(discipline);
		setDevicesSelect(device);
		setRegionSelect(region);
		setScrollPage(0);
		dispatch(getVideosFrom("all", "", discipline, device, region, 0, 20));
	};

	const fetchVideos = async () => {
		let incrementPage = scrollPage + 1;
		setScrollPage(incrementPage);
		await axios
			.get(`${process.env.REACT_APP_API_URL}getvideosfrom&f=all&di=${disciplineSelect}&de=${devicesSelect}&re=${regionSelect}&p=${incrementPage}&n=20`)
			.then((res) => {
				let responseVideos = res.data.videos;
				setVideos([...videos, ...responseVideos])
			})
			.catch((error) => {
				console.log(error)
			});
	};

	return (
		<main className="discipline-page">
			<div className="discipline-title">
				<div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
					<h1>
						<span onClick={() => clickBack()}/>
						Videos
					</h1>
				</div>
				<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="20%" height="2" fill="#1B3FFF"/>
					<rect x="20%" width="20%" height="2" fill="#D43D17"/>
					<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
					<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
					<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
				</svg>
			</div>

			<Filter
				onSelectFilter={onSelectFilter}
			/>

			<div className="content">
				{!isEmpty(videos) &&
				<InfiniteScroll
					dataLength={videos.length}
					next={fetchVideos}
					hasMore={true}
					style={{ overflow: 'hidden' }}
				>
					{videos.map((video) => {
						return (
							<div className="video" key={video.id}>
								<NavLink
									exact
									draggable="false"
									rel="noopener noreferrer"
									to={`/video/${video.id}`}
									onClick={() => window.scrollTo(0, 0)}
								>
									<img
										className="image"
										src={video.image_desktop}
										alt={`Apercu de ${video.name}`}
										width="100"
										height="66"
									/>

									<div className="title-container">
										<div className="play">
											<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
												 fill="none" xmlns="http://www.w3.org/2000/svg">
												<path className="round"
													  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
													  fill="#362CAE"/>
												<path className="play_sign"
													  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
													  fill="white"/>
											</svg>
										</div>
										<div className="video-date">
											<div className="line">
												<svg className="bar" width="100" height="4" viewBox="0 0 100 4" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect width="100" height="4" rx="2" fill="url(#paint0_radial_1607_3199)"/>
													<defs>
														<radialGradient id="paint0_radial_1607_3199" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 2) rotate(90) scale(2 27)">
															<stop stopColor="#4E45AF"/>
															<stop offset="1" stopColor="#362CAE"/>
														</radialGradient>
													</defs>
												</svg>
											</div>
											<div className="date">
												{video.addedon && moment(video.addedon).format("DD/MM/YYYY")}
											</div>
										</div>
										<h4>{video.name}</h4>
										<div className="video-description">
											{!isEmpty(video.descr) && video.descr.length > 180 ? video.descr.slice(0,180) + "..." : video.descr}
										</div>
									</div>
								</NavLink>
							</div>
						)
					})}
				</InfiniteScroll>
				}
			</div>

		</main>
	);
};

export default Videos;
