import { GET_INFO } from "../actions/info.action";
import {combineReducers} from "redux";

const initialState = [];

function getInfoReducer(state = initialState, action) {
	switch (action.type) {
		case GET_INFO:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({
	getInfoReducer
});
