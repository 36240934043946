import videojs from 'video.js';

const Plugin = videojs.getPlugin('plugin');

class Watermark extends Plugin {
    constructor(player, options) {
        super(player, options);
        this.videoEl = player.el();
        this.on("statechanged", this.prepareWatermark);
        this.default = {image: null, position: null, fadeTime: null};
        this.state = this.default;
        this.div = document.createElement('div');
        this.img = document.createElement('img');
    }

    updateState = (settings) => {
        this.setState(settings);
    };

    prepareWatermark = () => {
        if (!this.state.image) {
            this.reset();
            return;
        }

        if (this.div) {
            this.div.remove();
        }

        this.setupWatermark();

        this.player.addClass('vjs-watermark');

        // Setup watermark autofade
        if (this.state.fadeTime === null) {
            return;
        }
        this.player.on('play', () => this.fadeWatermark());
    };

    fadeWatermark = () => {
        this.timeaut = setTimeout(() => document.getElementsByClassName('vjs-watermark-content')[0]
            .classList.add('vjs-watermark-fade'), this.state.fadeTime
        );
    };

    showWaterMark = () => {
        if (this.player.preroll().ended && this.div && this.div.classList.contains("hidden")) {
            this.div.classList.remove("hidden")
        }
    };

    hideWaterMark = () => {
        if (this.div && !this.div.classList.contains("hidden")) {
            this.div.classList.add("hidden")
        }
    };

    reset() {
        this.setState(null);
        if (this.timeaut) {
            clearTimeout(this.timeaut);
        }
        if (this.div) {
            this.div.remove();
        }
    }

    setupWatermark = () => {
        this.div.classList.add('vjs-watermark-content');
        this.div.classList.add('hidden');
        this.div.classList.add(`vjs-watermark-${this.state.position}`);
        this.img.src = this.state.image;

        // if a url is provided make the image link to that URL.
        if (this.state.url) {
            const a = document.createElement('a');

            a.href = this.state.url;
            // if the user clicks the link pause and open a new window
            a.onclick = (e) => {
                e.preventDefault();
                this.player.pause();
                window.open(this.state.url);
            };
            a.appendChild(this.img);
            this.div.appendChild(a);
        } else {
            this.div.appendChild(this.img);
        }

        this.player.off("play", this.showWaterMark);
        this.player.on("play", this.showWaterMark);

        this.player.off("pause", this.hideWaterMark);
        this.player.on("pause", this.hideWaterMark);

        this.videoEl.appendChild(this.div);
    };
}

videojs.registerPlugin('watermark', Watermark);