import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import MyContextProvider from "./contexts/MyContext";
import "./style/style.scss";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/";
import { HelmetProvider } from "react-helmet-async";
import * as serviceWorker from "./serviceWorkerRegistration";
import {getTranslations} from "./actions/translations.action";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
const store = createStore(rootReducer, applyMiddleware(thunk));
const rootElement = document.getElementById("root");

store.dispatch(getTranslations());

if (process.env.REACT_APP_ENV === "production" && navigator.userAgent !== "ReactSnap") {

	const instance = createInstance({
		urlBase: 'https://statsgym.mytvchain.net',
		siteId: 2,
		disabled: false,
		heartBeat: {
			active: true,
			seconds: 10
		},
		configurations: {
			disableCookies: true,
			setSecureCookie: true,
			setRequestMethod: 'POST'
		}
	});

	if (rootElement.hasChildNodes()) {
		ReactDOM.hydrate(
			<MatomoProvider value={instance}>
				<React.StrictMode>
					<HelmetProvider>
						<MyContextProvider>
							<Provider store={store}>
								<App />
							</Provider>
						</MyContextProvider>
					</HelmetProvider>
				</React.StrictMode>
			</MatomoProvider>,
			rootElement
		);
	} else {
		ReactDOM.render(
			<MatomoProvider value={instance}>
				<React.StrictMode>
					<HelmetProvider>
						<MyContextProvider>
							<Provider store={store}>
								<App />
							</Provider>
						</MyContextProvider>
					</HelmetProvider>
				</React.StrictMode>
			</MatomoProvider>,
			rootElement
		);
	}

} else {
	if (rootElement.hasChildNodes()) {
		ReactDOM.hydrate(
			<React.StrictMode>
				<HelmetProvider>
					<MyContextProvider>
						<Provider store={store}>
							<App />
						</Provider>
					</MyContextProvider>
				</HelmetProvider>
			</React.StrictMode>,
			rootElement
		);
	} else {
		ReactDOM.render(
			<React.StrictMode>
				<HelmetProvider>
					<MyContextProvider>
						<Provider store={store}>
							<App />
						</Provider>
					</MyContextProvider>
				</HelmetProvider>
			</React.StrictMode>,
			rootElement
		);
	}

}

serviceWorker.register();