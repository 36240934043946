import React from "react";
import { useSelector } from "react-redux";
import {isEmpty} from "../Utils";
import Delete from "../account/Delete";

const NavBarMobileDelete = (props) => {
    const setTypeMenu = props.setTypeMenu;
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translate
    );
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu("preference")}/>
                        {translations && !isEmpty(translations["delete_account"])
                            ? translations["delete_account"]
                            : "Delete account"
                        }
                    </h1>
                </div>
            </div>
            <div className="content">
                <div className="security block">
                    <Delete/>
                </div>
            </div>
        </div>
    );
};

export default NavBarMobileDelete;
