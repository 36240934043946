import { combineReducers } from "redux";
import { GET_SETTING } from "../actions/setting.action";

const initialState = [];

function getSettingReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SETTING:
            return action.payload;
        default:
            return state;
    }
}

export default combineReducers({ getSettingReducer });
