import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "./Utils";
import { NavLink } from "react-router-dom";

import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import {useSelector} from "react-redux";

const Programmes = (props) => {
	const { programmes } = props;
	const slider = useRef();
	const eventRef = useRef(null);
	const [width, setWidth] = useState(null);
	const [itemsPerPage, setItemsPerPage] = useState(4);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const getWidth = () => {
		if (eventRef.current && eventRef.current.clientWidth) {
			const newWidth = eventRef.current.clientWidth;
			setWidth(newWidth);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", getWidth);
		return () => {
			window.removeEventListener("resize", getWidth);
		};
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 150);
		return () => clearTimeout(timeout);
	}, []);

	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: itemsPerPage,
		slidesToScroll: itemsPerPage,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />
	};

	useEffect(() => {
		if (!isEmpty(width) && Math.floor(width / 200) <= 4) {
			setItemsPerPage(Math.floor(width / 200));
		} else {
			setItemsPerPage(4);
		}
	}, [width]);

	return (
		<div ref={eventRef} className="category filter white">
			{!isEmpty(programmes) && (
				<div className="titles">
					<section
						className="flex-between m-auto"
					>
						<h3>
							{translations && !isEmpty(translations["categories_programs"])
								? translations["categories_programs"]
								: "Categories of programs"
							}
						</h3>
					</section>
				</div>
			)}

			<Slider ref={slider} {...settings} className="category-container hide-scroll customScroll">
				{!isEmpty(programmes) &&
				programmes.map((program) => {
					return (
						<NavLink
							exact
							key={program.id}
							to={`/categories/${program.name_web.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_")}`}
                            onClick={() => window.scrollTo(0, 0)}
						>
							<div className="cat">
								<img
									className="preview"
									src={program.pic}
									alt={`${program.name_web} logo`}
									title={`${program.name_web} logo`}
								/>
								<div className="title-block">
									<div className="title_content">
										<h4>
											<span>{program.name_web}</span>
											<svg className="bar" width="54" height="4" viewBox="0 0 54 4" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect width="54" height="4" rx="2" fill="url(#paint0_radial_1607_3199)"/>
												<defs>
													<radialGradient id="paint0_radial_1607_3199" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 2) rotate(90) scale(2 27)">
														<stop stopColor="#4E45AF"/>
														<stop offset="1" stopColor="#362CAE"/>
													</radialGradient>
												</defs>
											</svg>
										</h4>
										<div className="play">
											<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
												 fill="none" xmlns="http://www.w3.org/2000/svg">
												<path className="round"
													  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
													  fill="#362CAE"/>
												<path className="play_sign"
													  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
													  fill="white"/>
											</svg>
										</div>
									</div>
								</div>
							</div>
						</NavLink>
					);
				})}
			</Slider>
		</div>
	);
};

export default Programmes;
