import React, {Fragment, useContext, useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {isEmpty} from "../Utils";
import {MyContext} from "../../contexts/MyContext";
import {getCustomer, getListPaymentsMethods} from "../../actions/stripe.action";
import {toggleModal} from "../../actions/modal.action";
import Switch from "react-switch";
import moment from "moment";
import EditPayment from ".././account/subscription/submenu/EditPayment";
import EditBillingInfo from "../account/subscription/submenu/EditBillingInfo";


const NavBarMobileSubscriptionPayment = (props) => {
    const setTypeMenu = props.setTypeMenu;
    const {detachPaymentMethod, updateDefaultPaymentMethod} = useContext(MyContext);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translate
    );
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const customer = stripeReducer.getCustomerReducer;
    const payments = stripeReducer.getListPaymentsReducer;
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const [openEditPayment, setOpenEditPayment] = useState(false);
    const [openEditBillingInfo, setOpenEditBillingInfo] = useState(false);
    const [firsAsDefault, setFirsAsDefault] = useState(false);
    const [customerPlan, setCustomerPlan] = useState(false);

    useEffect(() => {
        if (!isEmpty(payments) && payments.length > 0) {
            setFirsAsDefault(false);
        } else {
            setFirsAsDefault(true);
        }
    }, [payments])

    useEffect(() => {
        if (!isEmpty(activePlan)) {
            setCustomerPlan(activePlan)
        }
    }, [activePlan]);

    const removeCard = async (paymentId) => {
        if (window.confirm("Are you sure you want to delete it?")) {
            if (!isEmpty(customer.id) && paymentId) {
                let result = await detachPaymentMethod(customer.id, paymentId);
                if (result.result === "ok") {
                    dispatch(getListPaymentsMethods(customer.id));
                    dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
                } else if (result.result === "bad" && result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error}));
                }
            }
        }
    };

    const setDefault = async (paymentId) => {
        if (!isEmpty(customer.id) && paymentId) {
            let result = await updateDefaultPaymentMethod(customer.id, paymentId);
            if (result.result === "ok") {
                dispatch(getCustomer())
                dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
            } else if (result.result === "bad" && result.error) {
                dispatch(toggleModal({opened: true, success: false, text: result.error}));
            }
        }
    };

    const defaultCard = useMemo(() => {
        // eslint-disable-next-line array-callback-return
        let card = payments.filter((item) => {
            if (customer.invoice_settings.default_payment_method === item.id || customer.default_source === item.id) {
                return item.id
            }
        })
        return card[0]?.id
    }, [customer.default_source, customer.invoice_settings.default_payment_method, payments]);

    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu("subscription")}/>
                        {translations && !isEmpty(translations["payment_info"])
                            ? translations["payment_info"]
                            : "Payment Information"
                        }
                    </h1>
                </div>
            </div>
            <div className="content">
                <h4>
                    {translations && !isEmpty(translations["means_pay"])
                        ? translations["means_pay"]
                        : "Means of Payment"
                    }
                </h4>
                <div>
                    {payments && (
                        payments.map((elem, index) => {
                            return (
                                <div className={`${defaultCard === elem.id ? "default method" : "method"}`}
                                     key={elem.id}>
                                    <div className="card d-flex my-4">
                                        <div className="image ml-4">
                                            <img src={`/img/icons/cards/${elem.card.brand}.svg`} id="brand" alt=""/>
                                        </div>
                                        <div className="my-auto mx-2 d-grid">
                                            <span className="number">{elem.card.brand} *** {elem.card.last4}</span>
                                            <span
                                                className="expire mt-2">Expire le {elem.card.exp_month < 10 ? `0${elem.card.exp_month}` : elem.card.exp_month}/{elem.card.exp_year}</span>
                                        </div>
                                        <div className="d-grid ml-4 my-auto">
                                            <label htmlFor={`default_${elem.id}`} id="defLabel">Default</label>
                                            <Switch
                                                onChange={() => setDefault(elem.id)}
                                                checked={defaultCard === elem.id}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                offColor="#d6d6d6"
                                                onColor="#679bff"
                                                id={`default_${elem.id}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="edit my-auto mr-4 ml-2">
                                        <img src="/img/icons/delete.svg" alt="" onClick={() => removeCard(elem.id)}/>
                                    </div>
                                </div>
                            )
                        })
                    )}

                    <button
                        className="btn btn-stripe mt-2 small"
                        onClick={() => setOpenEditPayment(!openEditPayment)}
                        style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                    >Add method
                    </button>
                </div>

                {customerPlan && customerPlan.plan_active === "1" && (
                    <Fragment>
                        <h3 className="mt-4">Abonnement</h3>
                        <div className="method">
                            <div className="card d-flex my-4">
                                <div className="my-auto ml-4 d-grid">
                                    <span
                                        className="number">{!isEmpty(customerPlan.product?.metadata?.title) ? customerPlan.product?.metadata?.title : ""}</span>
                                    <span className="expire mt-2 mr-4">
                                        {customerPlan.type === "recurring" ? (customerPlan.recurring.interval === "year" ? "Yearly Subscription" : "Monthly Subscription") : "One-time"} {customerPlan.type === "recurring" ? "| Next bill: " + moment.unix(customerPlan.next_direct).format("DD-MM-YYYY") : ""} | {(customerPlan.unit_amount / 100).toLocaleString('eu-EU', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    })}
                                    </span>
                                </div>
                            </div>
                            {/*<div className="edit my-auto mr-4">
                            <img src="/img/icons/edit.svg" alt=""/>
                        </div>*/}
                        </div>
                    </Fragment>
                )}
            </div>
            <div className="content subscription">
                <h3 className="mt-4">Billing information</h3>
                <div className="method">
                    <div className="card d-flex my-4">
                        <div className="my-auto ml-4">
                            <img src="/img/icons/location.svg" alt=""/>
                        </div>
                        {customer && !isEmpty(customer.shipping) && (
                            <div className="my-auto ml-4 d-grid">
                                <span className="number">{customer.shipping.name}</span>
                                <span className="expire mt-2 mr-2">
                            {customer.shipping?.address.postal_code}, {customer.shipping?.address.line1} {customer.shipping?.address.line2}<br/>{customer.shipping?.address.city}, {customer.shipping?.address.state}, {customer.shipping?.address.country}<br/>
                            </span>
                            </div>
                        )}
                    </div>
                    <div className="edit my-auto mr-4">
                        <img src="/img/icons/edit.svg" alt=""
                             onClick={() => setOpenEditBillingInfo(!openEditBillingInfo)}/>
                    </div>
                </div>
                {openEditPayment && (
                    <EditPayment
                        onClose={() => setOpenEditPayment(!openEditPayment)}
                        onSuccess={() => console.log("add success")}
                        setAsDefault={firsAsDefault}
                    />
                )}
                {openEditBillingInfo && customer && (
                    <EditBillingInfo
                        onClose={() => setOpenEditBillingInfo(!openEditBillingInfo)}
                    />
                )}
            </div>
        </div>
    );
};

export default NavBarMobileSubscriptionPayment;
