import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { MyContext } from "../contexts/MyContext";
import {isEmpty} from "./Utils";

const Footer = () => {
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [hoverReg,setHoverReg] = useState(false);
	const [hoverLog,setHoverLog] = useState(false);
	const boxStyleLog = {
		backgroundColor: hoverLog  && !isEmpty(settings) ? settings.hover_color : "" 
	};
	const boxStyleReg = {
		backgroundColor: hoverReg  && !isEmpty(settings) ? settings.hover_color : ""
	};
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	return (
		<footer style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
			<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="20%" height="3" fill="#1B3FFF"/>
				<rect x="20%" width="20%" height="3" fill="#D43D17"/>
				<rect x="40%" width="20%" height="3" fill="#4DAEC9"/>
				<rect x="60%" width="20%" height="3" fill="#ECC24A"/>
				<rect x="80%" width="20%" height="3" fill="#9AB94F"/>
			</svg>
			<div className="logo-section">
				<NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
					<img src={`${process.env.REACT_APP_CACHE_SERVER}/images/logo.png`} alt="logo fuchs" className="logo"/>
				</NavLink>
				<div className="socials">
					{!isEmpty(settings) && !isEmpty(settings.social_link_twitter) && (
						<a href={settings.social_link_twitter} target="_blank" rel="noreferrer">
							<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.6">
									<path d="M21.0357 40.6696C32.1011 40.6696 41.0714 31.6993 41.0714 20.6339C41.0714 9.56844 32.1011 0.598145 21.0357 0.598145C9.97029 0.598145 1 9.56844 1 20.6339C1 31.6993 9.97029 40.6696 21.0357 40.6696Z" stroke="white"/>
									<path fillRule="evenodd" clipRule="evenodd" d="M27.9331 17.1518C27.9402 17.3141 27.9429 17.48 27.9429 17.6468C27.9429 22.7419 24.2641 28.6146 17.5371 28.6146C15.4693 28.6146 13.5478 27.976 11.9287 26.881C12.2168 26.9168 12.5068 26.9357 12.802 26.9357C14.5166 26.9357 16.091 26.3213 17.3417 25.2872C15.744 25.254 14.3926 24.1419 13.9278 22.6092C14.1517 22.6549 14.3801 22.6809 14.6156 22.6809C14.9501 22.6809 15.2739 22.6316 15.579 22.5446C13.9064 22.1903 12.645 20.6307 12.645 18.7652V18.7168C13.1383 19.0065 13.7012 19.1796 14.3025 19.1984C13.3212 18.5087 12.6763 17.3284 12.6763 15.9903C12.6763 15.2836 12.8564 14.6235 13.1696 14.0522C14.9733 16.384 17.6709 17.9186 20.7074 18.0809C20.6459 17.7957 20.6129 17.5042 20.6129 17.2002C20.6129 15.0728 22.2525 13.3481 24.2721 13.3481C25.3247 13.3481 26.2757 13.8172 26.9411 14.5643C27.7734 14.3903 28.5566 14.0719 29.2631 13.6298C28.992 14.5293 28.4103 15.2836 27.6548 15.7607C28.3952 15.6683 29.1026 15.4603 29.7573 15.1535C29.2658 15.9284 28.6476 16.6083 27.9331 17.1518Z" fill="white"/>
								</g>
							</svg>
						</a>
					)}


					{!isEmpty(settings) && !isEmpty(settings.social_link_linkedin) && (
						<a href={settings.social_link_linkedin} target="_blank" rel="noreferrer">
							<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.6">
									<path d="M21.1072 40.6696C32.1727 40.6696 41.143 31.6993 41.143 20.6339C41.143 9.56844 32.1727 0.598145 21.1072 0.598145C10.0418 0.598145 1.07153 9.56844 1.07153 20.6339C1.07153 31.6993 10.0418 40.6696 21.1072 40.6696Z" stroke="white"/>
									<path d="M16.1351 28.8301V16.5405H12.2305V28.8301H16.1354H16.1351ZM14.1835 14.8628C15.5448 14.8628 16.3923 13.919 16.3923 12.7397C16.3668 11.5336 15.5448 10.6162 14.2094 10.6162C12.873 10.6162 12.0002 11.5336 12.0002 12.7397C12.0002 13.919 12.8474 14.8628 14.1579 14.8628H14.1832H14.1835ZM18.2962 28.8301H22.2004V21.9678C22.2004 21.6009 22.226 21.2332 22.329 20.9712C22.6112 20.237 23.2536 19.4771 24.3325 19.4771C25.745 19.4771 26.3103 20.6039 26.3103 22.2562V28.8301H30.2145V21.7837C30.2145 18.0089 28.2885 16.2524 25.7197 16.2524C23.6136 16.2524 22.6886 17.4839 22.1748 18.3227H22.2007V16.5408H18.2965C18.3474 17.6938 18.2962 28.8305 18.2962 28.8305V28.8301Z" fill="white"/>
								</g>
							</svg>
						</a>
					)}

					{!isEmpty(settings) && !isEmpty(settings.social_link_instagram) && (
						<a href={settings.social_link_instagram} target="_blank" rel="noreferrer">
							<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.6">
									<path d="M21.1785 40.6696C32.244 40.6696 41.2143 31.6993 41.2143 20.6339C41.2143 9.56844 32.244 0.598145 21.1785 0.598145C10.1131 0.598145 1.14282 9.56844 1.14282 20.6339C1.14282 31.6993 10.1131 40.6696 21.1785 40.6696Z" stroke="white"/>
									<path d="M24.6019 12.437H17.6693C15.0593 12.437 12.9392 14.5571 12.9392 17.1671V24.0951C12.9392 26.7097 15.0593 28.8297 17.6693 28.8297H24.5973C27.2119 28.8297 29.3274 26.7097 29.3274 24.0997V17.1671C29.3319 14.5571 27.2119 12.437 24.6019 12.437ZM27.8209 24.2187C27.8209 25.9541 26.4151 27.3599 24.6797 27.3599H17.5914C15.856 27.3599 14.4503 25.9541 14.4503 24.2187V17.1305C14.4503 15.395 15.856 13.9893 17.5914 13.9893H24.6843C26.4197 13.9893 27.8254 15.395 27.8254 17.1305V24.2187H27.8209Z" fill="white"/>
									<path d="M21.1355 16.4209C18.7864 16.4209 16.8816 18.3258 16.8816 20.6748C16.8816 23.0238 18.7864 24.9286 21.1355 24.9286C23.4845 24.9286 25.3893 23.0238 25.3893 20.6748C25.3893 18.3258 23.4845 16.4209 21.1355 16.4209ZM21.1355 23.4313C19.6107 23.4313 18.3789 22.195 18.3789 20.6748C18.3789 19.15 19.6152 17.9182 21.1355 17.9182C22.6603 17.9182 23.892 19.1545 23.892 20.6748C23.892 22.1996 22.6603 23.4313 21.1355 23.4313Z" fill="white"/>
									<path d="M25.5313 17.2496C26.0775 17.2496 26.5204 16.8068 26.5204 16.2605C26.5204 15.7143 26.0775 15.2715 25.5313 15.2715C24.9851 15.2715 24.5422 15.7143 24.5422 16.2605C24.5422 16.8068 24.9851 17.2496 25.5313 17.2496Z" fill="white"/>
								</g>
							</svg>
						</a>
					)}

					{!isEmpty(settings) && !isEmpty(settings.social_link_youtube) && (
						<a href={settings.social_link_youtube} target="_blank" rel="noreferrer">
							<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.6">
									<path d="M21.2501 40.6696C32.3155 40.6696 41.2858 31.6993 41.2858 20.6339C41.2858 9.56844 32.3155 0.598145 21.2501 0.598145C10.1847 0.598145 1.21436 9.56844 1.21436 20.6339C1.21436 31.6993 10.1847 40.6696 21.2501 40.6696Z" stroke="white"/>
									<g clipPath="url(#clip0_3107_2645)">
										<path fillRule="evenodd" clipRule="evenodd" d="M29.2058 15.0671C30.019 15.2812 30.6601 15.9083 30.8791 16.7036C31.2856 18.1565 31.27 21.1847 31.27 21.1847C31.27 21.1847 31.27 24.1977 30.8791 25.6506C30.6601 26.4459 30.019 27.073 29.2058 27.2871C27.7202 27.6694 21.7778 27.6694 21.7778 27.6694C21.7778 27.6694 15.8511 27.6694 14.3498 27.2718C13.5367 27.0577 12.8955 26.4306 12.6766 25.6353C12.2856 24.1977 12.2856 21.1694 12.2856 21.1694C12.2856 21.1694 12.2856 18.1565 12.6766 16.7036C12.8955 15.9083 13.5523 15.2659 14.3498 15.0518C15.8354 14.6694 21.7778 14.6694 21.7778 14.6694C21.7778 14.6694 27.7202 14.6694 29.2058 15.0671ZM24.8272 21.1693L19.8856 23.9528V18.3857L24.8272 21.1693Z" fill="white"/>
									</g>
								</g>
								<defs>
									<clipPath id="clip0_3107_2645">
										<rect width="19" height="13" fill="white" transform="translate(12.2856 14.6694)"/>
									</clipPath>
								</defs>
							</svg>
						</a>
					)}

					{!isEmpty(settings) && !isEmpty(settings.social_link_dailymotion) && (
						<a href={settings.social_link_dailymotion} target="_blank" rel="noreferrer">
							<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.6">
									<path d="M21.3214 40.6696C32.3868 40.6696 41.3571 31.6993 41.3571 20.6339C41.3571 9.56844 32.3868 0.598145 21.3214 0.598145C10.2559 0.598145 1.28564 9.56844 1.28564 20.6339C1.28564 31.6993 10.2559 40.6696 21.3214 40.6696Z" stroke="white"/>
									<path d="M28.2856 9.59814V29.4562H24.2163V27.9358C23.144 29.0118 21.7008 29.5981 20.0131 29.5981C16.3222 29.5981 13.2856 26.3468 13.2856 22.2689C13.2856 18.191 16.535 14.8943 20.0131 14.8943C21.7008 14.8943 23.144 15.5134 24.2163 16.6097V10.441L28.2856 9.59814ZM20.9356 25.7405C21.8452 25.7397 22.7173 25.366 23.3605 24.7014C24.0037 24.0369 24.3653 23.1358 24.3661 22.1959C24.3654 21.2561 24.0038 20.3549 23.3606 19.6902C22.7174 19.0256 21.8453 18.6518 20.9356 18.651C20.026 18.6518 19.1538 19.0256 18.5106 19.6902C17.8675 20.3549 17.5058 21.2561 17.5051 22.1959C17.5059 23.1358 17.8676 24.0369 18.5108 24.7014C19.1539 25.366 20.026 25.7397 20.9356 25.7405Z" fill="white"/>
								</g>
							</svg>
						</a>
					)}
					{!isEmpty(settings) && !isEmpty(settings.social_link_facebook) && (
						<a href={settings.social_link_facebook} target="_blank" rel="noreferrer">
							<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.6">
									<path d="M21.3929 40.6696C32.4583 40.6696 41.4286 31.6993 41.4286 20.6339C41.4286 9.56844 32.4583 0.598145 21.3929 0.598145C10.3275 0.598145 1.35718 9.56844 1.35718 20.6339C1.35718 31.6993 10.3275 40.6696 21.3929 40.6696Z" stroke="white"/>
									<path fillRule="evenodd" clipRule="evenodd" d="M25.7841 17.6644H23.0491V15.9891C23.0491 15.3604 23.4951 15.2133 23.8091 15.2133H25.7395V12.4474L23.0812 12.4375C20.1285 12.4375 19.4567 14.5012 19.4567 15.8214V17.6644H17.7502V20.5156H19.4567V28.5811H23.0491V20.5156H25.4701L25.7841 17.6644Z" fill="white"/>
								</g>
							</svg>
						</a>
					)}
				</div>
				<a href="https://company.mytvchain.com/" target="_blank" rel="noreferrer" className="powered-by">
					{translations && !isEmpty(translations["powered_by"])
						? translations["powered_by"]
						: "Powered by"
					}
					<img src="/img/icons/mytvchain.png" alt="MyTvChain Logo"/>
				
				</a>
				<div className="copyrights">
					<span>
						{translations && !isEmpty(translations["footer_copyright"])
							? parse(translations["footer_copyright"])
							: parse(`Copyright © 2021 ${process.env.REACT_APP_CLIENT_NAME} <br /> All rights reserved.`)
						}
					</span>
					{process.env.REACT_APP_CGU && (
						<span>
							<a href={process.env.PUBLIC_URL + "/files/" + process.env.REACT_APP_CGU} target="_blank" rel="noreferrer noopener" className="no-wrap">
								{translations && !isEmpty(translations["terms_of_services"])
									? parse(translations["terms_of_services"])
									: "terms_of_services"}{" "}
							</a>
						</span>
					)}

				</div>

			</div>
			<section className="discover">
				<ul>
					<li>
						<NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
							{translations && !isEmpty(translations["menu_home"])
								? translations["menu_home"]
								: "Home"
							}
						</NavLink>
					</li>
					<li>
						<NavLink
							exact
							to="/discipline"
							onClick={() =>
								document.querySelector(".main-header").classList.remove("menu-open")
							}
						>
							{translations && !isEmpty(translations["menu_disciplines"])
								? translations["menu_disciplines"]
								: "Disciplines"
							}
						</NavLink>
					</li>
					<li>
						<NavLink
							exact
							to="/directs"
							onClick={() =>
								document.querySelector(".main-header").classList.remove("menu-open")
							}
						>
							{translations && !isEmpty(translations["menu_directs"])
								? translations["menu_directs"]
								: "Directs"
							}
						</NavLink>
					</li>
				</ul>
				<ul>
					
					{!isEmpty(settings) && !isEmpty(settings.club_near_me_link) && (
						<li>
							<a
								href={settings.club_near_me_link}
								target="_blank"
								rel="noopener noreferrer"
							>
								{translations && !isEmpty(translations["club_near_me"])
									? translations["club_near_me"]
									: "Clubs near me"
								}
							</a>
						</li>
					)}
					<li
						id="contact-button"
					>
						<a
							href={`mailto:${!isEmpty(settings) ? settings.contact_email : ""}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{translations && !isEmpty(translations["contact"])
								? translations["contact"]
								: "Contact"
							}
						</a>
					</li>

					{!isEmpty(settings) && !isEmpty(settings.link_policy) && (
						<li>
							<NavLink to="/politique-de-confidentialite">
								{translations && !isEmpty(translations["privacy_policy"])
									? translations["privacy_policy"]
									: "Privacy policy"
								}
							</NavLink>
						</li>
					)}
					{!isEmpty(settings) && !isEmpty(settings.link_legals) && (
						<li>
							<a
								href={`${settings.link_legals }`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{translations && !isEmpty(translations["legals"])
									? translations["legals"]
									: "Legals"
								}
							</a>
						</li>
					)}
				</ul>
			</section>
			<section className="login-block">
				{!isAuth && (
					<div className="login">
						<ul>
							<li className="button button-transparent" style={boxStyleLog} onMouseEnter={() => setHoverLog(!hoverLog)} onMouseLeave={() => setHoverLog(!hoverLog)}>
								<NavLink to="/account/login">
									{translations && !isEmpty(translations["button_login"])
										? translations["button_login"]
										: "Log in"}
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
								</NavLink>
							</li>

							<li className="button button-transparent" style={boxStyleReg} onMouseEnter={() => setHoverReg(!hoverReg)} onMouseLeave={() => setHoverReg(!hoverReg)}>
								<NavLink to="/account/register">
									{translations && !isEmpty(translations["button_register"])
										? translations["button_register"]
										: "Register"
									}
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
								</NavLink>
							</li>
						</ul>
					</div>
				)}
			</section>
		</footer>
	);
};

export default Footer;
